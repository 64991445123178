import { useEffect, useState } from 'react';
import { addDays, subDays } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { pl } from 'date-fns/locale';
import { RootState } from '../../reducers';
import { setDate } from '../../reducers/choosedDate';

import './react-datepicker.css';
import { axiosInstance } from '../../config/axios';
import { apiUrls } from '../../config/url';

registerLocale('pl', pl);

export const Calendar = () => {
  const dispatch = useDispatch();
  const { date } = useSelector(({ choosedDate }: RootState) => choosedDate);
  const [data, setData] = useState<Date[]>([]);

  const getAllToDosOfMonth = async (date: string) => {
    const res = await axiosInstance(apiUrls.todos.getTodosOfMonth(new Date(date).toString()));
    if (res?.data?.payload) {
      const flated = res?.data?.payload.map((x: Record<string, string>) => new Date(x.day));
      setData(flated);
    }
  };

  useEffect(() => {
    if (!date) return;
    getAllToDosOfMonth(date);
  }, [date]);

  return (
    <DatePicker
      inline
      selected={date ? new Date(date) : null}
      onChange={(date) => dispatch(setDate(date?.toDateString()))}
      onMonthChange={(date) => getAllToDosOfMonth(date.toString())}
      locale="pl"
      highlightDates={data}
    />
  );
};
