import { Box, SystemProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface AbsoluteContainerProps {
  h?: SystemProps['h'];
  w?: SystemProps['w'];
  children: ReactNode;
}

export const AbsoluteContainer: FC<AbsoluteContainerProps> = ({
  children,
  h = '100%',
  w = '100%',
}) => (
  <Box position="absolute" left="0px" top="0px" w={w} h={h}>
    {children}
  </Box>
);
