import { Accordion } from '@chakra-ui/react';
import { FC } from 'react';
import { ListOfTrainingFilesPerHeadband } from 'types';
import { FileType, useFiles } from '../../hooks/useFiles';
import { AccordionComponent } from '../common/Accordion';
import { FileItem } from './FileItem';

interface FileListProps {
  headbands: ListOfTrainingFilesPerHeadband;
}

export const FilesList: FC<FileListProps> = ({ headbands = [] }) => {
  const { downloadFile } = useFiles();
  const renderElements = headbands.map(({ headbandNr, trainings }) => (
    <AccordionComponent key={headbandNr} textWithoutTranslation={headbandNr}>
      {trainings.map(({ eegData: { originalName }, questionnaire, id }) => (
        <>
          <FileItem
            key={originalName}
            text={originalName}
            onClick={() => downloadFile(id, FileType.EEG, { asName: originalName })}
          />
          <FileItem
            key={questionnaire}
            text={questionnaire}
            onClick={() => downloadFile(id, FileType.QUESTIONNAIRE, { asName: questionnaire })}
          />
        </>
      ))}
    </AccordionComponent>
  ));

  return (
    <Accordion marginTop="5" marginBottom="5" allowToggle>
      {renderElements}
    </Accordion>
  );
};
