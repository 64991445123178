/* eslint-disable no-restricted-syntax */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ListOfTrainingFilesPerHeadband } from 'types';
import { getFiles as getFilesAction } from '../reducers/files';
import { useApi } from './useApi';
import { apiUrl } from '../config/url';
import { handleError } from '../helpers/handleError';

const extensionMap: { [key: string]: string } = {
  diodes: 'csv.gz',
  questionnaire: 'csv',
};

export enum FileType {
  EEG = 'diodes',
  QUESTIONNAIRE = 'questionnaire',
}

export type DownloadFileOptions = {
  asName?: string;
};

export interface UseTrainingApi {
  getFiles: () => void;
  downloadFile: (trainingId: string, type: FileType, options: DownloadFileOptions) => void;
  isLoading: boolean;
}

export const useFiles = (): UseTrainingApi => {
  const dispatch = useDispatch();
  const { get, inProgress } = useApi();

  const getFiles = useCallback(async (): Promise<void> => {
    const result = await get<ListOfTrainingFilesPerHeadband>('users/getFiles');
    if (result) {
      dispatch(getFilesAction(result));
    }
  }, []);

  const downloadFile = useCallback(
    async (
      trainingId: string,
      type: FileType,
      options: DownloadFileOptions = {},
    ): Promise<void> => {
      const { asName = 'file' } = options;
      // @Here is used basic fetch due to problem with receive res in useApi
      const res = await fetch(`${apiUrl}csv/${type}/${trainingId}`, {
        headers: { 'content-type': 'text/csv' },
      });
      if (res.status === 200) {
        const extension = extensionMap[type];
        const blob = await res.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', `${asName}.${extension}`);
        document.body.appendChild(link);
        link.click();
      } else {
        // eslint-disable-next-line camelcase
        const { error_code = 0 } = await res.json();
        handleError(error_code);
      }
    },
    [],
  );

  return {
    getFiles,
    downloadFile,
    isLoading: inProgress,
  };
};
