import { AiOutlineRight } from 'react-icons/ai';
import { FormField, InputText, ButtonsContainer, Button } from '../..';
import { DefaultFormProps } from '../../../types/components';
import { InputPassword } from '../../FormField';

export const AccountSetupForm = ({ isDisabled }: DefaultFormProps) => (
  <>
    <FormField isRequired labelTxt="label.email" name="email">
      <InputText />
    </FormField>
    <FormField isRequired labelTxt="label.password" name="password">
      <InputPassword />
    </FormField>
    <FormField isRequired labelTxt="label.repeatPassword" name="repeatPassword">
      <InputPassword />
    </FormField>
    <ButtonsContainer alignTo="right" isSpaced>
      <Button
        type="submit"
        text="action.next"
        isDisabled={isDisabled}
        rightIcon={<AiOutlineRight />}
      />
    </ButtonsContainer>
  </>
);
