import Shell from './shell.png';
import Fish from './fish.png';
import IceCream from './iceCream.png';
import Tree from './tree.png';
import Boat from './boat.png';

export const shell = () => Shell;
export const fish = () => Fish;
export const iceCream = () => IceCream;
export const tree = () => Tree;
export const boat = () => Boat;
