import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { UserDetails, UserRole, Status } from 'types';
import { handleError } from '../helpers/handleError';
// import { axiosInstance } from '../config/axios';
import { ApiPayload } from '../hooks/useApi';
import { apiUrls } from '../config/url';

export interface UserState {
  details?: UserDetails;
  inProgress: boolean;
  isError: boolean;
  logoutInProgress: boolean;
  isSetup: boolean;
}

const initialState: UserState = {
  inProgress: false,
  isError: false,
  logoutInProgress: false,
  isSetup: true,
  details: {
    id: '',
    email: null,
    login: '',
    role: UserRole.User,
    status: Status.Waiting,
    patientsLimit: 5,
  },
};

export const getUserDetails = createAsyncThunk('panel/account/data', async (isHidden?: boolean) =>
  axios
    .get<ApiPayload<Promise<UserDetails>>>('user/panel/account/data')
    .then((res) => res.data.payload)
    .catch((err) => {
      if (!isHidden) handleError(err?.response);
    }),
);

export const logout = createAsyncThunk('auth/logout', async () =>
  axios
    .post(apiUrls.auth.logout)
    .then()
    .catch((err) => handleError(err)),
);

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.details = action.payload;
    },
    setIsSetup: (state, action: PayloadAction<boolean>) => {
      state.isSetup = action.payload;
    },
    clearUserData: (state) => {
      state.inProgress = false;
      state.isError = false;
      state.logoutInProgress = false;
      state.isSetup = true;
      if ('details' in state) {
        state.details = undefined;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.inProgress = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.inProgress = false;
      state.details = action.payload ?? undefined;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.inProgress = false;
      state.isError = true;
    });
    builder.addCase(logout.pending, (state) => {
      state.logoutInProgress = true;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.logoutInProgress = false;
      state.details = undefined;
    });
    builder.addCase(logout.rejected, (state) => {
      state.logoutInProgress = false;
      state.details = undefined;
    });
  },
});

export const { setUser, setIsSetup, clearUserData } = user.actions;

export default user.reducer;
