export const colors = {
  main: '#1A1F5A',
  light: '#FFFFFF',
  mainBg: '#F9F9F9',
  border: '#D8D8E1',
  activeGreen: '#00FF77',
  inActiveGreen: '#00ff775a',
  mainGreen: '#72EBAB',
  gray: '#9A9A9A',
};
