import { ReactNode } from 'react';
import { FitFactorState, FitLevel } from '../hooks/bluetooth/useFitFactor';

export enum GameType {
  Fish = 'fish',
  Apple = 'apple',
  Boat = 'boat',
  IceCream = 'iceCream',
  Shell = 'shell',
  Tree = 'tree',
  MushroomsPicking = 'mushromPicking',
  GreenMeadow = 'greenMeadow',
  FlowerPicking = 'flowerPicking',
}

export enum GameNames {
  Fish = 'Wyprawa na ryby',
  Boat = 'Żaglówka na jeziorze',
  IceCream = 'Automat z lodami',
  MushroomsPicking = 'Zbieranie grzybów',
  GreenMeadow = 'Zielona Łąka',
  FlowerPicking = 'Wiosenne Kwiaty',
  Tree = 'Kwitnąca jabłoń',
  Shell = 'Muszle nad morzem',
  Apple = 'Zbieranie jabłek',
}

export type NewData = {
  newFitFactor: FitFactorState | undefined;
  newFitLevel: FitLevel;
};

export enum ModalContentTypes {
  EMPTY = '',
  ON_LEAVE = 'onLeave',
  ON_WRONG_CONNECT = 'onWrongConnect',
  ON_LOW_BATTERY = 'onLowBattery',
}

export type ModalContentMap = {
  [key: string]: ReactNode;
};
