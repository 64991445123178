import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AuthAccountSetupLoginRequest, UserResponse } from 'types';
import { useDispatch } from 'react-redux';
import { useApi } from '../../../hooks/useApi';
import { successNotification } from '../../../helpers/notifications';
import {
  accountSetupLoginSchema,
  AccountSetupLoginType,
} from '../../../formSchemas/accountSetupLoginSchema';
import { setUser } from '../../../reducers/user';
import { FormWrapper, AccountSetupLoginForm, Heading } from '../../../components';
import { apiUrls } from '../../../config/url';

export const AccountSetupLogin = () => {
  const methods = useForm<AccountSetupLoginType>({
    resolver: zodResolver(accountSetupLoginSchema()),
  });
  const { post, inProgress } = useApi();
  const dispatch = useDispatch();

  const submitHandler = async (formData: AccountSetupLoginType) => {
    const res = await post<UserResponse, AuthAccountSetupLoginRequest>(
      apiUrls.auth.accountSetupLogin,
      formData,
    );
    if (res) {
      dispatch(setUser(res.user));
      successNotification('notification.registeredCorrectly');
    }
  };

  return (
    <FormWrapper<AccountSetupLoginType> methods={methods} submitHandler={submitHandler}>
      <Heading as="h1" text="header.register" />
      <AccountSetupLoginForm isDisabled={inProgress} />
    </FormWrapper>
  );
};
