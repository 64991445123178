import { AxiosResponse } from 'axios';
import { ApiErrorCode } from 'types';
import { errorNotification } from './notifications';

const getErrorMessage = (error: ApiErrorCode) => {
  switch (error) {
    case ApiErrorCode.OtherError:
    case ApiErrorCode.LoginIsTaken:
    case ApiErrorCode.BadCredentials:
    case ApiErrorCode.FormValidationError:
    case ApiErrorCode.EmailIsTaken:
    case ApiErrorCode.AccountIsAlreadySetupUp:
    case ApiErrorCode.AccountIsNotActivated:
    case ApiErrorCode.AccountIsBlocked:
    case ApiErrorCode.HeadbandNrIsUsed:
    case ApiErrorCode.CannotDownloadFile:
      return `apiErrorCode.${error}`;
    default:
      return 'apiErrorCode.0';
  }
};

export const handleError = (response?: AxiosResponse): void => {
  if (!response) {
    errorNotification(getErrorMessage(ApiErrorCode.OtherError));
    return;
  }

  errorNotification(getErrorMessage(response.data.error_code));
};
