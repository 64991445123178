import { createSlice } from '@reduxjs/toolkit';
import { sumGameProgress } from '../helpers/sumGameProgress';
import { GAMESCONFIG } from '../config/games';
import { GameType } from '../types';

export interface GameResults {
  gameTime: number;
  highLevelTime: number;
  awards: unknown; // @ Here must be unknown because of different types of awards
}

export interface TrainingResultInitialState {
  gameSession: number;
  gameType?: GameType;
  games: GameResults[];
  total: GameResults;
}

const initialState: TrainingResultInitialState = {
  gameSession: GAMESCONFIG.SESSION_AMOUNT,
  gameType: undefined,
  games: [],
  total: {
    gameTime: 0,
    highLevelTime: 0,
    awards: null,
  },
};

const training = createSlice({
  name: 'training',
  initialState,
  reducers: {
    setGameType: (state, action) => {
      state.gameType = action.payload;
    },
    addGame: (state, action) => {
      state.games = [...state.games, action.payload];
      state.gameSession -= 1;
    },
    setTotal: (state) => {
      state.total = state.games.reduce((prev, next) => ({
        gameTime: prev.gameTime + next.gameTime,
        highLevelTime: prev.highLevelTime + next.highLevelTime,
        awards:
          typeof prev.awards === 'string' && typeof next.awards === 'string'
            ? sumGameProgress(prev.awards, next.awards)
            : (prev.awards as number) + (next.awards as number),
      }));
    },
    clearResult: (state) => {
      const { gameType, games, total, gameSession } = initialState;
      state.gameSession = gameSession;
      state.gameType = gameType;
      state.games = games;
      state.total = total;
    },
  },
});

export const { setGameType, addGame, setTotal, clearResult } = training.actions;

export default training.reducer;
