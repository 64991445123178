// noinspection SuspiciousTypeOfGuard

export const urls = {
  auth: {
    login: '/auth/login',
    adminLogin: '/auth/admin/login',
    resetPassword: '/auth/reset-password',
    accountSetupLogin: '/auth/account-setup-login',
    accountSetup: '/auth/account-setup',
  },
  connect: {
    connecting: '/dashboard/connecting',
    inProgress: '/dashboard/connecting/in-progress',
    fail: '/dashboard/connecting/fail',
    success: '/dashboard/connecting/success',
    lowBattery: '/dashboard/connecting/low-battery',
  },
  preparing: {
    prepare: '/dashboard/preparing',
    adjustTheHeadband: '/dashboard/preparing/adjust-the-headband',
    checkDiods: '/dashboard/preparing/check-diods',
  },
  dashboard: {
    bluetooth: '/dashboard/bluetooth',
    patient: {
      menu: '/dashboard/patient/menu',
    },
    choosePatient: '/dashboard/choose-patient',
    multiPatients: '/dashboard/multi-patients',
    connect: {
      connecting: '/dashboard/connecting',
      inProgress: '/dashboard/connecting/in-progress',
      fail: '/dashboard/connecting/fail',
      success: '/dashboard/connecting/success',
      lowBattery: '/dashboard/connecting/low-battery',
    },
    preparing: {
      prepare: '/dashboard/preparing',
      adjustTheHeadband: '/dashboard/preparing/adjust-the-headband',
      checkDiods: '/dashboard/preparing/check-diods',
    },
    questionnaire: {
      feel: '/dashboard/questionnaire/feel',
      sleep: '/dashboard/questionnaire/sleep-time',
      after: '/dashboard/questionnaire/after',
    },
    games: {
      gameChoose: '/dashboard/game-choose',
      gameInstruction: '/dashboard/game-instruction',
      gameIntro: '/dashboard/game-intro',
      game: '/dashboard/game',
    },
    headband: {
      start: '/dashboard/headband-start',
    },
    traning: {
      summary: '/dashboard/traning-summary',
      addTraning: '/dashboard/traning-planner',
    },
    progress: {
      trainingProgress: '/dashboard/progress',
    },
  },
};

export const apiUrls = {
  auth: {
    login: '/auth/login',
    adminLogin: '/auth/login/admin',
    logout: 'auth/logout',
    resetPassword: '/auth/reset-password',
    setPassword: (userId: string, tokenId: string) => `/auth/set-password/${userId}/${tokenId}`,
    activate: (userId: string, tokenId: string) => `/auth/activate/${userId}/${tokenId}`,
    accountSetupLogin: '/auth/account-setup-login',
    accountSetup: '/auth/account-setup',
  },
  user: {
    updateUser: '/users/update',
    getUsers: '/users/list',
    getUser: '/users',
  },
  questionnaire: {
    beforeTraning: 'questionnaire/before-traning',
  },
  patients: {
    patient: '/patients/patient',
    firstUsage: (id: string) => `/patients/first-usage/${id}`,
    patientId: (id: string) => `/patients/patient/${id}`,
  },
  traning: {
    createTraning: '/traning/traning',
    finishTraning: (id: string) => `/traning/traning/${id}`,
    getAllTranings: (id: string) => `/patients/${id}/progress`,
    getLast7Tranings: (id: string) => `/traning/last-7/${id}`,
  },

  todos: {
    getTodosOfDay: (date: string) => `/todo/todos/${date}`,
    getTodosOfMonth: (date: string) => `/todo/todos/month/${date}`,
    addTodo: '/todo/todo',
    deleteTodo: (id: string) => `/todo/todo/${id}`,
    editTodo: '/todo/todo',
  },
  gameResult: {
    gameResults: '/game-result/game-results',
  },
  faq: {
    data: 'faq',
    addTopic: 'faq/topic',
    addQuestion: 'faq/question',
  },
};

export const apiUrl =
  typeof process.env.REACT_APP_API_URL === 'string'
    ? `${process.env.REACT_APP_API_URL.trim()}/`
    : 'http://localhost:3001/';
