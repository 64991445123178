import { AiOutlineRight } from 'react-icons/ai';
import { FormField, InputText, ButtonsContainer, Button, Link } from '../..';
import { DefaultFormProps } from '../../../types/components';
import { InputPassword } from '../../FormField';
import { urls } from '../../../config/url';
import { useModal } from '../../../hooks/useModal';

export const LoginForm = ({ isDisabled }: DefaultFormProps) => {
  const { onOpen } = useModal();

  return (
    <>
      <FormField isRequired labelTxt="label.email" name="email">
        <InputText />
      </FormField>
      <FormField isRequired labelTxt="label.password" name="password">
        <InputPassword />
      </FormField>
      <Link onClick={onOpen} text="action.forgotPassword" />
      <ButtonsContainer alignTo="space-between" isSpaced>
        <Button
          variant="light"
          to={urls.auth.accountSetupLogin}
          text="action.register"
          isDisabled={isDisabled}
        />
        <Button
          type="submit"
          text="action.next"
          isDisabled={isDisabled}
          rightIcon={<AiOutlineRight />}
        />
      </ButtonsContainer>
    </>
  );
};
