import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Last7TrainingsRes, PatientsTrainingsRes, TraningRes } from 'types';
import { axiosInstance } from '../config/axios';

import { ApiPayload } from '../hooks/useApi';
import { apiUrls } from '../config/url';

interface TraningInitialState {
  traning: string;
  tranings: PatientsTrainingsRes;
  last7trainings: Last7TrainingsRes;
}

const initialState: TraningInitialState = {
  traning: '',
  tranings: {
    trainings: [],
  },
  last7trainings: {
    trainings: [],
  },
};

interface FinishTrainingReq {
  traningId: string;
  questionnaireFeel: string;
  questionnaireSleep: string;
  questionnaireAfter: string;
  gameType: any;
  focus: number | undefined;
  awards: unknown;
  gameLongTime: number | undefined;
}

// TODO move this to training reducer
export const createTraning = createAsyncThunk(
  'traning/create',
  async (patientId: string | undefined) => {
    try {
      const res = await axiosInstance.post<ApiPayload<Promise<TraningRes>>>(
        apiUrls.traning.createTraning,
        { patientId },
      );
      return res?.data?.payload;
    } catch {
      return undefined;
    }
  },
);

export const finishTraning = createAsyncThunk('traning/finish', async (body: FinishTrainingReq) => {
  try {
    const res = await axiosInstance.post<ApiPayload<Promise<TraningRes>>>(
      apiUrls.gameResult.gameResults,
      body,
    );
    return res?.data?.payload;
  } catch {
    return undefined;
  }
});

export const getTranings = createAsyncThunk(
  'traning/getAll',
  async ({ patientId, selected, date }: any) => {
    try {
      const res = await axiosInstance.post<ApiPayload<Promise<PatientsTrainingsRes>>>(
        apiUrls.traning.getAllTranings(patientId),
        {
          selected,
          date,
        },
      );
      return res?.data?.payload;
    } catch {
      return undefined;
    }
  },
);

export const getLast7Trainings = createAsyncThunk('traning/getLast7', async (patientId: string) => {
  try {
    const res = await axiosInstance.get<ApiPayload<Promise<Last7TrainingsRes>>>(
      apiUrls.traning.getLast7Tranings(patientId),
    );
    return res?.data?.payload;
  } catch {
    return undefined;
  }
});

const todos = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    setTraning: (state, action: PayloadAction<any>) => {
      state.traning = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createTraning.fulfilled, (state, { payload }) => {
        if (payload) {
          state.traning = payload.id;
        }
      })
      .addCase(finishTraning.fulfilled, (state, { payload }) => {
        if (payload) {
          state.traning = payload.id;
        }
      })
      .addCase(getTranings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.tranings = payload;
        }
      })
      .addCase(getLast7Trainings.fulfilled, (state, { payload }) => {
        if (payload) {
          state.last7trainings = payload;
        }
      }),
});

export const { setTraning } = todos.actions;

export default todos.reducer;
