import { Message } from '../../bluetooth/message';
import { SectionEnum } from '../../types/bluetoothMessage';
import { DataSectionTmtck } from '../../bluetooth/dataSections/dataSectionTmtck';
import { MeasurementTrackInformation } from '../../bluetooth/measurementTrackInformation';

export const getMillisecondsFromMessage = (message: Message, ms: number) => {
  const dataSection = message.getFirstSection<DataSectionTmtck>(SectionEnum.Tmtck);

  return dataSection?.getMillisecondsTimeTick() ?? +new Date() - ms;
};

/**
 * Correct received time tick from message.
 *
 * Sometimes messages contains incorrect time tick.
 * Difference between current and previous data section should be equal to eegSectionPeriod.
 * If it is lower than that, it's possible to overlap sample data.
 *
 * Single data are produce every eegMillisecondsSampleInterval:
 * if the difference is equal to it then the sample will overlap and we must take care of it.
 * in this case we increase received time tick.
 * @param timeTickFromMessage received time tick from device
 * @param previousEegTimeTick previous value of time tick from headband message containing eeg data, used to correct offset
 * @param eegManager MeasurementTrackInformation about Eeg,
 * @return corrected time tick.
 */
export const getTimeTickCorrection = (
  timeTickFromMessage: number,
  previousEegTimeTick: number,
  eegManager: MeasurementTrackInformation,
) => {
  // correct only when data range may collide
  const isDataRangeColliding =
    timeTickFromMessage - previousEegTimeTick < eegManager.getSectionPeriod();
  // correct only when samples may overlap
  const areSamplesOverlapping =
    (timeTickFromMessage - previousEegTimeTick) %
      eegManager.getMillisecondsIntervalBetweenSamples() ===
    0;

  return timeTickFromMessage + Number(isDataRangeColliding && areSamplesOverlapping);
};
