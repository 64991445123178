export const styles = {
  global: {
    '::-webkit-scrollbar': {
      width: '8px',
      borderRadius: '8px',
      height: '8px',
      backgroundColor: 'rgba(200, 200, 200, .5)',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(57,57,57, .6)',
      borderRadius: '8px',
    },
    body: {
      fontFamily: 'Work Sans, sans-serif',
      color: 'main',
      background: 'mainBg',
    },
    '.flatpickr-calendar.inline': {
      width: '100%',
      '.flatpickr-innerContainer,.flatpickr-rContainer,.flatpickr-weekdays,.flatpickr-days,.dayContainer':
        {
          width: '100%',
          maxWidth: '100%',
        },
    },
  },
};
