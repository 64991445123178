import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ApiOkResult } from 'types';
import { errorNotification, successNotification } from '../../../helpers/notifications';
import { useApi } from '../../../hooks/useApi';
import { AppSpinner } from '../../../components';
import { apiUrls, urls } from '../../../config/url';

export const Activate = () => {
  const { userId, tokenId } = useParams<{ userId: string; tokenId: string }>();
  const { patch } = useApi();
  const navigate = useNavigate();

  const activateUser = async () => {
    if (!userId || !tokenId) {
      errorNotification('apiErrorCode.0');
      return;
    }
    const res = await patch<ApiOkResult>(apiUrls.auth.activate(userId, tokenId));
    if (res) successNotification('notification.activated');
    navigate(urls.auth.login);
  };

  useEffect(() => {
    activateUser();
  }, []);

  return <AppSpinner />;
};
