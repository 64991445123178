import { Dispatch, SetStateAction } from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { sidebarPhotoIntro } from '../images/sidebarPhotoIntroStep/index';
import { MyModal } from '../common/MyModal';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
  skip: () => Promise<void>;
}

export const Step0 = ({ setStep, skip }: Props) => {
  const handleSkip = () => {
    setStep((step: number) => step + 7);
    skip();
  };
  return (
    <MyModal isCenter>
      <Flex gap="50px" width="700px">
        <Image src={sidebarPhotoIntro()} maxW="80%" />
        <Flex direction="column" justify="space-evenly">
          <Text text="dashboardTutorial.welcome" variant="BlueHeader" fontSize="42px" />
          <Text text="dashboardTutorial.step0" variant="blueParagraph" maxW="300px" />
          <Flex gap="20px">
            <Button text="action.skip" variant="light" onClick={() => handleSkip()} />
            <Button
              text="action.next"
              rightIcon={<ChevronRightIcon w="7" h="7" />}
              onClick={() => setStep((step: number) => step + 1)}
            />
          </Flex>
        </Flex>
      </Flex>
    </MyModal>
  );
};
