import { Spinner, Center } from '@chakra-ui/react';

interface Props {
  fullScreen?: boolean;
}

export const AppSpinner = ({ fullScreen }: Props) => (
  <Center width={fullScreen ? '100vw' : '100%'} height={fullScreen ? '100vh' : '100%'}>
    <Spinner size="xl" color="main" />
  </Center>
);
