import { GridItem } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface FaqQuestionsProps {
  children: ReactNode;
}

export const FaqQuestions: FC<FaqQuestionsProps> = ({ children }) => (
  <GridItem rowSpan={4}>{children}</GridItem>
);
