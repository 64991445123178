import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { AddHeadbandLogRequest, HeadbandLogsEntity } from 'types';
import {
  Inop,
  MeasureTracksDescription,
  ReceivedCode,
  SectionEnum,
  StateSignalAcquisition,
} from '../types';
import { Message } from '../bluetooth/message';
import { DataSectionInfo } from '../bluetooth/dataSections/dataSectionInfo';
import { DataSectionSts } from '../bluetooth/dataSections/dataSectionSts';
import { DataSectionEeg } from '../bluetooth/dataSections/dataSectionEeg';
import { DataSectionHeg } from '../bluetooth/dataSections/dataSectionHeg';
import { axiosInstance } from '../config/axios';
import { ApiPayload } from '../hooks/useApi';
import { DataSectionErrDev } from '../bluetooth/dataSections/dataSectionErrDev';
import { macAddressFromByteArray } from '../helpers/byte';
import { isSectionType } from '../helpers/bluetooth';

export interface EegSection {
  inop: Inop;
}

interface EegSideDetails {
  back?: EegSection; // O(1/2) / (L/R)1 / ch(00/05)
  backSide?: EegSection; // T(5/6) / (L/R)2 / ch(01/06)
  side?: EegSection; // T(3/4) / (L/R)3 / ch(02/07)
  frontSide?: EegSection; // F(7/8) / (L/R)4 / ch(03/08)
  front?: EegSection; // Fp(1/2) / (L/R)5 / ch(04/09)
}

interface SectionDetails<T> {
  left: T;
  right: T;
}

interface HegSideDetails {
  heg?: number;
  state?: StateSignalAcquisition;
}

interface StsDetails {
  battery: number;
}

interface ConnectionDetails {
  isConfigured: boolean;
  isConnected: boolean;
  isError: boolean;
}

interface EegDetails {
  previousEegTimeTick: number;
}

export interface HeadbandState {
  eeg: EegDetails & SectionDetails<EegSideDetails>;
  heg: SectionDetails<HegSideDetails>;
  sts: StsDetails;
  info: MeasureTracksDescription;
  connection: ConnectionDetails;
}

const initialState: HeadbandState = {
  connection: {
    isConfigured: false,
    isConnected: false,
    isError: false,
  },
  sts: { battery: 0 },
  info: {},
  eeg: {
    left: {},
    right: {},
    previousEegTimeTick: 0,
  },
  heg: { left: {}, right: {} },
};

const sendErrorMessage = (message: Message) =>
  axiosInstance.post<AddHeadbandLogRequest, ApiPayload<Promise<HeadbandLogsEntity>>>(
    '/headband/logs/',
    {
      textMessage: message.toText(),
      deviceMac: macAddressFromByteArray(message.messageHeader?.getDevId() ?? new Uint8Array()),
    },
  );

const headbandDataReducer = (state: Draft<HeadbandState>, action: PayloadAction<Message>) => {
  const { payload: message } = action;

  if (message.messageHeader?.getRcvCode() !== ReceivedCode.NoError) sendErrorMessage(message);

  Object.values(message.sections).forEach((section) => {
    switch (section.getSectionEnum()) {
      case SectionEnum.Heg: {
        if (!isSectionType<DataSectionHeg>(section, SectionEnum.Heg)) return;
        if (section.getChannelNumber() === 0) state.heg.left = section.getData();
        else state.heg.right = section.getData();
        break;
      }
      case SectionEnum.Eeg: {
        if (!isSectionType<DataSectionEeg>(section, SectionEnum.Eeg)) return;

        const EEG_SIDE: (keyof EegSideDetails)[] = [
          'back',
          'backSide',
          'side',
          'frontSide',
          'front',
        ];

        const side = section.getChannelNumber() < 5 ? 'left' : 'right';
        const face =
          EEG_SIDE[section.getChannelNumber() - Number(section.getChannelNumber() >= 5) * 5];

        state.eeg[side][face] = section.getData();
        break;
      }
      case SectionEnum.Info: {
        if (!isSectionType<DataSectionInfo>(section, SectionEnum.Info)) return;

        state.info = section.measureTracksDescription;
        break;
      }
      case SectionEnum.Sts: {
        if (!isSectionType<DataSectionSts>(section, SectionEnum.Sts)) return;
        state.sts = section.getData();
        if (section.getData().battery) state.connection.isConfigured = true;
        break;
      }
      case SectionEnum.ErrDev: {
        if (!isSectionType<DataSectionErrDev>(section, SectionEnum.ErrDev)) return;
        sendErrorMessage(message);
        break;
      }
      default:
    }
  });
};

const headband = createSlice({
  name: 'headband',
  initialState,
  reducers: {
    setHeadbandData: headbandDataReducer,
    setHeadbandConnected: (state, action: PayloadAction<boolean>) => {
      state.connection.isConnected = action.payload;
    },
    setHeadbandError: (state, action: PayloadAction<boolean>) => {
      state.connection.isError = action.payload;
    },
  },
});

export const { setHeadbandData, setHeadbandConnected, setHeadbandError } = headband.actions;

export default headband.reducer;
