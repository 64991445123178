import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ApiOkResult, PasswordResetRequest } from 'types';
import { Dispatch, SetStateAction } from 'react';
import { FormWrapper, Heading, ResetPasswordForm } from '../../../components';
import { resetPasswordSchema, ResetPasswordType } from '../../../formSchemas/resetPasswordSchema';
import { useApi } from '../../../hooks/useApi';
import { apiUrls } from '../../../config/url';
import { ResetState } from '../../../types/components';

export const ResetPasswordInput = ({
  setStep,
}: {
  setStep: Dispatch<SetStateAction<ResetState>>;
}) => {
  const methods = useForm<ResetPasswordType>({
    resolver: zodResolver(resetPasswordSchema()),
  });
  const { post, inProgress } = useApi();

  const submitHandler = async (formData: ResetPasswordType) => {
    const res = await post<ApiOkResult, PasswordResetRequest>(apiUrls.auth.resetPassword, formData);
    if (res) {
      methods.reset();
      setStep(ResetState.Ready);
    }
  };

  return (
    <FormWrapper<ResetPasswordType> methods={methods} submitHandler={submitHandler}>
      <Heading layerStyle="modalHeader" as="h1" text="header.resetPassword" />
      <ResetPasswordForm isDisabled={inProgress} />
    </FormWrapper>
  );
};
