import { Text as ChakraText } from '@chakra-ui/react';
import { AriaRole, ReactNode, CSSProperties } from 'react';

import { As } from '@chakra-ui/system/dist/declarations/src/system.types';
import { __ } from '../../../helpers/i18n';

interface Props {
  text?: string;
  variant?: string;
  children?: ReactNode;
  as?: As;
  to?: string;
  maxW?: string;
  fontSize?: string;
  fontWeight?: string;
  align?: 'center' | 'left' | 'right' | 'justify';
  textDecoration?: string;
  cursor?: string;
  onClick?: () => void;
  role?: AriaRole;
  style?: CSSProperties;
}

export const Text = ({
  text,
  children,
  variant = 'baseStyle',
  fontSize = '16px',
  cursor = 'text',
  onClick,
  align = 'left',
  role = 'text',
  ...rest
}: Props) => (
  <ChakraText
    role={role}
    variant={variant}
    fontSize={fontSize}
    cursor={cursor}
    {...rest}
    align={align}
    onClick={onClick}
    wordBreak="break-word"
  >
    {children}
    {text && __(text)}
  </ChakraText>
);
