import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { store } from '../config/store';
import { clearUserData } from '../reducers/user';

export const initializeAxios = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error),
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(clearUserData());
      }
      return error;
    },
  );

  return axiosInstance;
};
