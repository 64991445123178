import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch } from 'react-redux';
import { LoginRequest, UserResponse } from 'types';
import { LoginForm, FormWrapper, Heading } from '../../../components';
import { LoginType, loginSchema } from '../../../formSchemas/loginSchema';
import { useApi } from '../../../hooks/useApi';
import { successNotification } from '../../../helpers/notifications';
import { setUser, setIsSetup } from '../../../reducers/user';
import { apiUrls } from '../../../config/url';
import { ResetPassword } from '../ResetPassword';

export const Login = () => {
  const methods = useForm<LoginType>({
    resolver: zodResolver(loginSchema()),
  });
  const { post, inProgress } = useApi();
  const dispatch = useDispatch();

  const submitHandler = async (formData: LoginType) => {
    const res = await post<UserResponse, LoginRequest>(apiUrls.auth.login, formData);
    if (res) {
      successNotification('notification.loggedIn');
      dispatch(setIsSetup(false));
      dispatch(setUser(res.user));
    }
  };

  return (
    <>
      <FormWrapper<LoginType> methods={methods} submitHandler={submitHandler}>
        <Heading as="h1" text="header.login" />
        <LoginForm isDisabled={inProgress} />
      </FormWrapper>
      <ResetPassword />
    </>
  );
};
