import { Box, Flex } from '@chakra-ui/react';

import { FormField, InputText, InputNumber, ButtonsContainer, Button } from '../..';

interface Props {
  onClose: () => void;
}

export const TrainingForm = ({ onClose }: Props) => (
  <>
    <Flex gap="20px" justify="center">
      <FormField isRequired labelTxt="label.time" name="hours" placeholder="placeholder.time">
        <InputNumber />
      </FormField>
      <Flex mt="5">
        <p style={{ fontSize: '30px', fontWeight: 'bold' }}>:</p>
      </Flex>
      <FormField
        isRequired
        labelTxt="label.minutes"
        name="minutes"
        placeholder="placeholder.minutes"
      >
        <InputNumber />
      </FormField>
      <FormField isRequired labelTxt="label.patient" name="patient">
        <InputText />
      </FormField>
    </Flex>

    <ButtonsContainer alignTo="right" isSpaced>
      <Button text="action.close" variant="light" onClick={() => onClose()} />
      <Button type="submit" text="action.send" />
    </ButtonsContainer>
  </>
);
