import { useEffect, useState } from 'react';
import { Modal } from '../../../components';
import { useModal } from '../../../hooks/useModal';
import { ResetPasswordInput } from './ResetPasswordInput';
import { ResetState } from '../../../types/components';
import { ResetPasswordReady } from './ResetPasswordReady';

export const ResetPassword = () => {
  const [step, setStep] = useState(ResetState.Input);
  const { isOpen } = useModal();

  useEffect(() => {
    if (!isOpen) setStep(ResetState.Input);
  }, [isOpen]);

  return (
    <Modal>
      {step === ResetState.Input ? (
        <ResetPasswordInput setStep={setStep} />
      ) : (
        <ResetPasswordReady />
      )}
    </Modal>
  );
};
