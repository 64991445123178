import { Dispatch } from 'react';
import { setIsOnline } from '../reducers/appStatus';
import { store } from '../config/store';
import { persistentNotification, successNotification } from './notifications';

export const setWindowState = (): void => {
  const listenToWindowEvent =
    (name: string, mapEventToAction: () => void) => (dispatch: Dispatch<() => void>) => {
      const handleEvent = () => {
        dispatch(mapEventToAction);
      };
      window.addEventListener(name, handleEvent);
    };

  if (store) {
    store.dispatch(
      listenToWindowEvent('offline', () => {
        setIsOnline(false);
        persistentNotification('application.isOffline');
      }),
    );

    store.dispatch(
      listenToWindowEvent('online', () => {
        setIsOnline(true);
        successNotification('application.isBackOnline');
      }),
    );

    store.dispatch(setIsOnline(window.navigator.onLine));
  }
};
