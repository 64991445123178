import styled from 'styled-components';
import { UnConnctedDotIcon, ConnctedDotIcon } from '../../../images/icons';

interface Props {
  top: string;
  left: string;
  isConncted: boolean;
}

const StyledDot = styled.div<Props>`
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  position: absolute;
`;

export const IsConnectedDot = ({ top, left, isConncted }: Props) => (
  <StyledDot top={top} left={left} isConncted={isConncted}>
    {isConncted ? <ConnctedDotIcon w="7" h="7" /> : <UnConnctedDotIcon w="7" h="7" />}
  </StyledDot>
);
