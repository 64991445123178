import { Flex } from '@chakra-ui/react';
import { ReactNode, useCallback } from 'react';

interface Props {
  children: ReactNode;
  alignTo?: 'left' | 'center' | 'right' | 'space-around' | 'space-between' | 'space-evenly';
  isVertical?: boolean;
  isSpaced?: boolean;
  ml?: string;
  mx?: number | string;
}

export const ButtonsContainer = ({
  children,
  isVertical,
  alignTo = 'left',
  isSpaced,
  ...rest
}: Props) => {
  const getProps = useCallback(() => {
    switch (alignTo) {
      case 'center':
        return { justifyContent: 'center' };
      case 'right':
        return { justifyContent: 'flex-end' };
      case 'left':
        return { justifyContent: 'flex-start' };
      case 'space-around':
        return { justifyContent: 'space-around' };
      case 'space-between':
        return { justifyContent: 'space-between' };
      case 'space-evenly':
        return { justifyContent: 'space-evenly' };
      default:
        return {};
    }
  }, []);

  return (
    <Flex
      gap={isSpaced ? 4 : 0}
      my={isSpaced ? 8 : 0}
      flexDir={isVertical ? 'column' : 'row'}
      {...rest}
      {...getProps()}
    >
      {children}
    </Flex>
  );
};
