import { FC } from 'react';
import { Text } from '../../common';

interface FaqTopicButtonProps {
  onClick: () => void;
  isChosen: boolean;
  text: string;
}

export const FaqTopicButton: FC<FaqTopicButtonProps> = ({ onClick, isChosen, text }) => (
  <Text
    role="button"
    cursor="pointer"
    onClick={onClick}
    fontSize="xl"
    fontWeight={isChosen ? 'bold' : '500'}
    textDecoration={isChosen ? 'underline' : ''}
  >
    {text}
  </Text>
);
