import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaqRes } from 'types';

export interface FaqDetails {
  isOpenFaq: boolean;
  faqData: FaqRes;
}

const initialState: FaqDetails = {
  isOpenFaq: false,
  faqData: [],
};

const faq = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    openFaq: (state) => {
      state.isOpenFaq = true;
    },
    closeFaq: (state) => {
      state.isOpenFaq = false;
    },
    setFaqData: (state, action: PayloadAction<FaqRes>) => {
      state.faqData = action.payload;
    },
  },
});

export const { openFaq, closeFaq, setFaqData } = faq.actions;

export default faq.reducer;
