import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaqRes } from '../../../neuroplay-backend/types/faq';
import { apiUrls } from '../config/url';
import { RootState } from '../reducers';
import { openFaq, closeFaq, setFaqData } from '../reducers/faq';
import { useApi } from './useApi';

export const useFaq = () => {
  const { isOpenFaq } = useSelector((state: RootState) => state.faq);
  const { get } = useApi();

  const dispatch = useDispatch();

  const onOpenFaq = () => dispatch(openFaq());

  const onCloseFaq = () => dispatch(closeFaq());

  const getFaqData = useCallback(async () => {
    const result = await get<FaqRes>(apiUrls.faq.data);
    if (result) {
      dispatch(setFaqData(result));
    }
  }, []);

  return { isOpenFaq, onOpenFaq, onCloseFaq, getFaqData };
};
