import { MouseEvent as ReactMouseEvent, useCallback } from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { __ } from '../../../helpers/i18n';
import { debounce } from '../../../helpers/debounce';
import { ButtonProps } from '../../../types/components';

export const Button = ({
  text,
  onClick,
  to,
  ml = '32px',
  children,
  type = 'button',
  variant = 'defaultVariant',
  ...rest
}: ButtonProps) => {
  const onTimedClick = useCallback(
    debounce((e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e);
    }, 250),
    [],
  );

  if (to) {
    return (
      <ChakraButton
        as={Link}
        variant={variant}
        type={type}
        to={to}
        onClick={onTimedClick}
        {...rest}
      >
        {text && __(text)}
        {children}
      </ChakraButton>
    );
  }

  return (
    <ChakraButton variant={variant} type={type} onClick={onTimedClick} {...rest}>
      {text && __(text)}
      {children}
    </ChakraButton>
  );
};
