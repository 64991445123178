import { DataSectionInfo } from '../bluetooth/dataSections/dataSectionInfo';
import { DataSectionRtc } from '../bluetooth/dataSections/dataSectionRtc';
import { DataSectionSts } from '../bluetooth/dataSections/dataSectionSts';
import { DataSectionEegSet } from '../bluetooth/dataSections/dataSectionEegSet';
import { DataSectionTmtck } from '../bluetooth/dataSections/dataSectionTmtck';
import { DataSectionEeg } from '../bluetooth/dataSections/dataSectionEeg';
import { DataSectionHeg } from '../bluetooth/dataSections/dataSectionHeg';
import { DataSectionFill } from '../bluetooth/dataSections/dataSectionFill';
import { DataSectionErrDev } from '../bluetooth/dataSections/dataSectionErrDev';
import { MeasurementTrackInformation } from '../bluetooth/measurementTrackInformation';

export enum Source {
  DeviceToMaster,
  MasterToDevice,
}

export enum OnDemand {
  Cyclic,
  ResponseToMaster,
}

export enum RequestedTransmissionType {
  Normal,
  RequestRetransmission,
}

export enum MessageType {
  NormalMessage,
  RetransmissionMessage,
}

export enum Encipher {
  Disabled,
  Enabled,
}

export enum ReceivedInstructionFromMaster {
  False,
  True,
}

export enum ReceivedCode {
  NoError,
  CounterWarning,
  CrcError,
  UnknownSectionWarning,
  UnknownMessage,
  SectionsDecodingWarning,
  CrcHeaderError,
  MessageToShort,
}

export enum Orientation {
  Normal,
  Inverted,
}

export enum MeasureTrackType {
  NotSet = 'msrccc - not set',
  Eeg = 'EEG',
  Heg = 'HEG',
  Acc = 'ACC',
  Spo = 'SPO',
  Gsr = 'GSR',
}

export enum Active {
  Inactive,
  Active,
}

export enum BatteryWarningCode {
  NoError,
  LowBatteryLevel10,
  LowBatteryLevel2,
  LowBatteryLevel1,
  BatteryFlat = 8, // Missing battery, or battery damaged.
  NoBattery = 15,
}

export enum BatteryStateMeasurementUnit {
  Meaningless, // Presentation method is not important: eg: data are not displayed/collected.
  Value,
  Percent,
  SwitchBetweenValueAndPercent,
}

export enum KeyPad {
  Null,
  Down,
  Up,
  Confirm,
  Cancel,
}

export enum SectionEnum {
  Unknown = 0x00_00, // 0
  // Accelerometer data section.
  Acc = 0x00_07, // 7
  // Electroencephalograph data section.
  Eeg = 0x00_0a, // 10
  // Galvanic skin response data section.
  Gsr = 0x00_0b, // 11
  // Hemoencephalography data section.
  Heg = 0x00_12, // 18
  // Device status section.
  Sts = 0x80_fa, // 33_018
  // Real time clock section.
  Rtc = 0x80_fb, // 33_019
  // Device information section.
  Info = 0x80_ff, // 33_023
  // Electroencephalograph filtering configuration data section.
  EegSet = 0x70_0a, // 28_682
  // Galvanic skin response configuration data section?.
  GsrSet = 0x70_0b, // 28_683
  // Command section data.
  Tcmd = 0xf0_01, // 61_441
  // Error device section data.
  ErrDev = 0xf0_02, // 61_442
  // Time tick data section.
  Tmtck = 0xf0_03, // 61_443
  // Serial numbers data section.
  SNum = 0xf0_f0, // 61_480
  // Empty data section (padding data, no informational).
  Fill = 0xf0_ff, // 61_495
}

// State of an electrode
export enum Inop {
  Ok,
  Error, // Electrode is not operating correctly - weak or lost contact with patient skin.
}

// Represents working mode of sensor.
export enum Simulation {
  Real,
  Simulation,
}

export enum Presence {
  Absent,
  Present,
}

export enum StateSignalAcquisition {
  NoError,
  WeakSignal,
  Reserved, // unused.
  SearchingSignal,
  SearchSignalTimeout,
  SignalLost,
  ObjectLost, // Missing measured object - sensor not attached correctly to patient body.
  SensorOff,
  UnknownState = 0xffff,
}

export enum AmplificationSignalCoefficient {
  InfraredEmitter,
  RedEmitter,
}

export enum Channel {
  Off,
  On,
}

export interface MessageHeaderType {
  // Frame number
  frNum: number;
  // Mac address converted to number [use macAddressFromByteArray to convert]
  devId: Uint8Array;
  // Number of data sections in data.
  frNS: number;
  // Body length with it's CRC
  frLen: number;
  // Origin of message
  src: Source;
  // Is transmitted as response to master [us]
  od: OnDemand;
  // Retransmission request flag
  rptLr: RequestedTransmissionType;
  // Retransmission flag
  rptd: MessageType;
  // Is enciphered
  cryp: Encipher;
  // Acknowledge transmission from master
  rcvd: ReceivedInstructionFromMaster;
  // Error code - 0 means OK
  // It mostly doesn't matter to us, since we can't really affect those errors
  // purpose of this was to test the device
  rcvCode: ReceivedCode;
  // crc is used for content validation [bit length etc.]
  hdrCrc?: number;
  // raw data of contents above [in bytes]
  rawMessageHeader: Uint8Array;
}

export interface DataSectionType {
  data: Uint8Array;
  header: Uint8Array;
  idS?: SectionEnum;
  nD?: number;
}

export interface MeasurementInformation {
  measuringDeviceDescription: string;
  samplingRate: number;
  pressure: number;
  temperature: number;
  airHumidity: number;
}

export interface MeasurementTrackInformationType extends MeasurementInformation {
  trackName: string;
  nch: number;
  in: string;
  fav: string;
  sf: number;
  lsb: number;
  bps: number;
  sper: number;
  rbps: number;
  rlsb: number;
  msrccc?: string;
}

export type MeasureTracksDescription = {
  [key in MeasureTrackType]?: MeasurementTrackInformation;
};

export enum DeviceError {
  NoError = 0x00_00,
  InitializationError = 0x00_10,
  ApplicationInitializationError = 0x00_20,
  MemoryConfigurationError = 0x00_21,
  ApplicationRestart = 0x00_2f,
  Shutdown = 0x00_30,
  BatteryFlatOrPowerError = 0x00_31,
  Examined = 0x00_32,
  PowerDownByUser = 0x00_33,
  PowerDownByWatchdog = 0x00_34,
  SoftwareReset = 0x00_35,
  Timeout = 0x00_36,
  BatteryCharging = 0x00_38,
  HardwareError = 0x00_3f,

  CyclicTransmissionBreak = 0x00_40,
  CtbBatteryFlatOrPowerError = 0x00_41,
  CtbExaminedObjectMissing = 0x00_42,
  CtbPowerDownByUser = 0x00_43,
  CtbPowerDownByWatchdog = 0x00_44,
  CtbSoftwareReset = 0x00_45,
  CtbBatteryCharging = 0x00_48,

  OperationModeChanged = 0x00_50,
  EndOfSimulationMode = 0x00_51,

  MemoryError = 0x00_60,
  SampleBufferOverflowWarning = 0x00_61,
  SampleBufferOverflow = 0x00_62,
  TransmissionBufferOverflow = 0x00_63,

  ConnectionLostMemoryError = 0x00_71,
  ConnectionLostWatchdog = 0x00_74,

  CommonTrackError = 0x01_00,
  EegTrackError = 0x01_0a,
  GsrTrackError = 0x01_0b,
  HegTrackError = 0x01_12,

  UnknownError = 0x00_ff,
}

export interface DataSectionInfoType extends DataSectionType {
  eeg?: Active;
  gsr?: Active;
  acc?: Active;
  heg?: Active;
  description?: string[];
  measureTracksDescription: MeasureTracksDescription;
}

export interface DataSectionRtcType extends DataSectionType {
  tSetR?: Active;
  rtcTmr?: number;
}

export interface DataSectionEegSetType extends DataSectionType {
  cFlt: Active;
}

export interface DataSectionStsType extends DataSectionType {
  orient: Orientation;
  event: number;
  keyPad: KeyPad;
  ts: Active;
  gsr: Active;
  eeg: Active;
  acc: Active;
  heg: Active;
  wrngCode: BatteryWarningCode;
  msrMd: BatteryStateMeasurementUnit;
  msrVal: number;
}

export interface DataSectionEegType extends DataSectionType {
  inop: Inop;
  sim: Simulation;
  chN: number;
  chOn: Channel;
  samples: number[];
}

export interface DataSectionHegType extends DataSectionType {
  spl: Presence;
  sim: Simulation;
  state: StateSignalAcquisition;
  // ASCoef in documentation - WTF
  amplificationSignalCoefficientValue?: number;
  amplificationSignalCoefficient?: AmplificationSignalCoefficient;
  heg?: number;
  pr?: number;
  signalStrength?: number;
}

export interface DataSectionTmtckType extends DataSectionType {
  millisecondsTimeTick: number;
}

export interface DataSectionFillType extends DataSectionType {
  desiredSectionLength: number;
}

export interface DataSectionErrDevType extends DataSectionType {
  errDevCode: DeviceError;
  errorDescription: string;
}

export type SectionType =
  | DataSectionInfo
  | DataSectionRtc
  | DataSectionSts
  | DataSectionEegSet
  | DataSectionEeg
  | DataSectionHeg
  | DataSectionTmtck
  | DataSectionFill
  | DataSectionErrDev;
