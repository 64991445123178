import { Modal as ChakraModal, ModalContent, ModalOverlay, ModalBody } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useModal } from '../../../hooks/useModal';

export const Modal = ({ children }: { children: ReactNode }) => {
  const { isOpen, onClose } = useModal();

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent>
        <ModalBody p={10}>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
};
