import { z } from 'zod';
import { emailValidation, passwordValidation, repeatPasswordValidation } from '../helpers/zod';

export const accountSetup = () =>
  z
    .object({
      email: emailValidation(),
      password: passwordValidation(),
      repeatPassword: passwordValidation(),
    })
    .refine(...repeatPasswordValidation());

export type AccountSetupType = z.TypeOf<ReturnType<typeof accountSetup>>;
