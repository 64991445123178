import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { ReactNode } from 'react';

interface Props<T> {
  methods: UseFormReturn<T>;
  submitHandler: SubmitHandler<T>;
  children: ReactNode;
}

export const FormWrapper = <T,>({ methods, submitHandler, children }: Props<T>) => (
  <form onSubmit={methods.handleSubmit(submitHandler)}>
    <FormProvider {...methods}>{children}</FormProvider>
  </form>
);
