import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdminUserDetailsRes, AdminUserList, AdminUserRes } from 'types';

interface AdminInitialState {
  userList: AdminUserList;
  chosenUser: AdminUserDetailsRes;
}

const adminState: AdminInitialState = {
  userList: [],
  chosenUser: {
    customName: '',
    email: '',
    headbandNr: '',
    patientsLimit: 0,
    id: '',
  },
};

const admin = createSlice({
  name: 'admin',
  initialState: adminState,
  reducers: {
    getUsers: (state, action: PayloadAction<AdminUserList>) => {
      state.userList = action.payload;
    },
    getUser: (state, action: PayloadAction<AdminUserDetailsRes>) => {
      state.chosenUser = action.payload;
    },
    updateUser: (state, action: PayloadAction<AdminUserRes>) => {
      state.userList = state.userList.map((user) => {
        if (user.id === action.payload.id) return action.payload;
        return user;
      });
    },
  },
});

export const { getUsers, getUser, updateUser } = admin.actions;

export default admin.reducer;
