import { Button } from './button';
import { Form } from './form';
import { Heading } from './heading';
import { Input } from './input';
import { Text } from './text';
import { Progress } from './progress';

export const components = {
  Button,
  Heading,
  Form,
  Input,
  Text,
  Progress,
  Link: {
    baseStyle: {
      textDecoration: 'underline',
      _hover: {
        opacity: 0.75,
      },
    },
  },
  Container: {
    variants: {
      mainAuth: {
        maxW: '100vw',
        display: 'flex',
        p: 0,
      },
      centerContentWrapper: {
        position: 'relative',
        maxW: '60%',
        h: '100vh',
        boxSizing: 'border-box',
      },
    },
  },
  Modal: {
    baseStyle: {
      dialog: {
        background: 'mainBg',
      },
    },
  },
};
