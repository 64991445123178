/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Accordion } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FaqQuestionRes } from 'types';
import { colors } from '../../../config/theme/colors';
import { __ } from '../../../helpers/i18n';
import { RootState } from '../../../reducers';
import { AccordionComponent } from '../../common';
import { FaqContainer } from '../FaqContainer';
import { FaqHeader } from '../FaqHeader';
import { FaqQuestions } from '../FaqQuestions';
import { FaqTopicButton } from '../FaqTopicButton';
import { FaqTopics } from '../FaqTopics';

interface FaqContentProps {
  onClose: () => void;
}

export const FaqContent: FC<FaqContentProps> = ({ onClose }) => {
  const { faqData } = useSelector((state: RootState) => state.faq);
  const [selectedQuestions, setSelectedQuestions] = useState<FaqQuestionRes[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<string>('');

  useEffect(() => {
    const newSet = faqData.find(({ topic }) => topic === selectedTopic);
    if (newSet) {
      setSelectedQuestions(newSet.questions);
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (faqData[0]) setSelectedTopic(faqData[0].topic);
  }, [faqData]);

  const renderTopics = () =>
    faqData.map(({ topic, id }) => (
      <FaqTopicButton
        key={id}
        onClick={() => setSelectedTopic(topic)}
        isChosen={topic === selectedTopic}
        text={topic}
      />
    ));

  const renderQuestions = () =>
    selectedQuestions.map(({ answer, question, id }) => (
      <AccordionComponent
        key={id}
        textWithoutTranslation={question}
        _last={{ borderBottomWidth: 0 }}
        _first={{ borderTopWidth: 0 }}
        style={{ borderTopColor: colors.border }}
      >
        {answer}
      </AccordionComponent>
    ));

  return (
    <FaqContainer>
      <FaqHeader onClose={onClose} text="header.faqHeader" />
      <FaqTopics>{renderTopics()}</FaqTopics>
      <FaqQuestions>
        <Accordion marginTop="5" marginBottom="5" allowToggle width="100%">
          {renderQuestions()}
        </Accordion>
      </FaqQuestions>
    </FaqContainer>
  );
};
