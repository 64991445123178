import { z } from 'zod';
import { CustomErrorParams, number } from 'zod/lib/types';
import { __ } from './i18n';
import { regex } from './regex';

export const textValidation = ({ min = 0, max = 64 } = {}) =>
  z
    .string({
      required_error: __('error.required'),
      invalid_type_error: __('error.required'),
    })
    .min(min, { message: __('error.minText', { min }) })
    .max(max, { message: __('error.maxText', { max }) })
    .nullish();

export const numberValidation = () => z.number({ required_error: __('error.required') });

export const textIsRequired = () =>
  z
    .string({
      required_error: __('error.required'),
      invalid_type_error: __('error.required'),
    })
    .nonempty({ message: __('error.required') });

export const emailValidation = () =>
  textValidation({ min: 8 }).and(textIsRequired().email({ message: __('error.invalidEmail') }));

export const passwordValidation = () =>
  textValidation({ min: 8, max: 64 }).and(
    textIsRequired()
      .regex(regex.capitalRequired, { message: __('error.passwordRequirements') })
      .regex(regex.numberRequired, { message: __('error.passwordRequirements') }),
  );

export const repeatPasswordValidation = (): [
  <T extends { password: string; repeatPassword: string }>(data: T) => boolean,
  CustomErrorParams,
] => [
  (data) => data.password === data.repeatPassword,
  {
    message: __('error.passwordsDontMatch'),
    path: ['repeatPassword'],
  },
];
