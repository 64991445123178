import { createSlice } from '@reduxjs/toolkit';
import { GameNames, GameType } from '../types';
import TreeImageBig from '../components/GameCards/images/TreeBig.png';
import ShellImageBig from '../components/GameCards/images/ShellBig.png';
import AppleImageBig from '../components/GameCards/images/AppleBig.png';
import BoatImageBig from '../components/GameCards/images/BoatBig.png';
import IceCreamImageBig from '../components/GameCards/images/IceCreamBig.png';
import FishImageBig from '../components/GameCards/images/FishBig.png';
// it will be needed in future
// import TreeImageSmall from '../components/GameCards/images/TreeSmall.png';
// import ShellImageSmall from '../components/GameCards/images/ShellSmall.png';
// import AppleImageSmall from '../components/GameCards/images/AppleSmall.png';
// import BoatImageSmall from '../components/GameCards/images/BoatSmall.png';
// import FishImageSmall from '../components/GameCards/images/FishSmall.png';
// import IceCreamImageSmall from '../components/GameCards/images/IceCreamSmall.png';
// import maleGrzyby from '../components/GameCards/images/mushroms/maleGrzyby.png';
// import maleLaka from '../components/GameCards/images/mushroms/maleLaka.png';
// import maleKwiaty from '../components/GameCards/images/mushroms/maleKwiaty.png';

export interface Game {
  gameName: string;
  image: string;
  gameType: GameType;
}

export interface GameDetails {
  description: string;
  instruction: string;
  modalImage: string;
  gameName: string;
  gameType: string;
}

export interface GameInitialState {
  bestToStartWith: Game[];
  rest: Game[];
  choosenGame: GameDetails;
}

const initialState: GameInitialState = {
  bestToStartWith: [
    {
      gameName: GameNames.Boat,
      image: BoatImageBig,
      gameType: GameType.Boat,
    },
    {
      gameName: GameNames.Shell,
      image: ShellImageBig,
      gameType: GameType.Shell,
    },
    {
      gameName: GameNames.Apple,
      image: AppleImageBig,
      gameType: GameType.Apple,
    },
  ],
  rest: [
    { gameName: GameNames.Boat, image: BoatImageBig, gameType: GameType.Boat },
    { gameName: GameNames.IceCream, image: IceCreamImageBig, gameType: GameType.IceCream },
    { gameName: GameNames.Fish, image: FishImageBig, gameType: GameType.Fish },
    {
      gameName: GameNames.Tree,
      image: TreeImageBig,
      gameType: GameType.Tree,
    },
    {
      gameName: GameNames.Shell,
      image: ShellImageBig,
      gameType: GameType.Shell,
    },
    {
      gameName: GameNames.Apple,
      image: AppleImageBig,
      gameType: GameType.Apple,
    },
    // it will be needed in future
    // { gameName: 'Zbieranie grzybów', image: maleGrzyby, gameType: GameType.MushroomsPicking },
    // { gameName: 'Zielona Łąka', image: maleLaka, gameType: GameType.GreenMeadow },
    // { gameName: 'Wiosenna Kwiaty', image: maleKwiaty, gameType: GameType.FlowerPicking },
  ],
  choosenGame: {
    description: '',
    instruction: '',
    modalImage: '',
    gameName: '',
    gameType: '',
  },
};

const game = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameDetails: (state, action) => {
      state.choosenGame = action.payload;
    },
    clearGameDetails: (state, action) => {
      state.choosenGame = {
        description: '',
        instruction: '',
        modalImage: '',
        gameName: '',
        gameType: '',
      };
    },
    setGameType: (state, action) => {
      state.choosenGame.gameType = action.payload;
    },
  },
});

export const { setGameDetails, clearGameDetails, setGameType } = game.actions;

export default game.reducer;
