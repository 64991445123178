import { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
  gap?: string;
}

export const PreparingContentWrapper = ({ children, gap = '30px' }: Props) => (
  <Flex flexDirection="column" gap={gap} w="100%" maxW="850px" marginInline="auto">
    {children}
  </Flex>
);
