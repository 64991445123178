import { useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import { pl } from 'date-fns/locale';
import { setProgressDate } from '../../reducers/choosedDate';

registerLocale('pl', pl);

const Button = styled.button`
  display: block;
  background-color: #87fabd;
  color: white;
  padding: 10px 10px;
  border-radius: 5px;
  font-weight: bold;
`;

const CustomInput = ({ value, onClick }: any) => (
  <Button onClick={onClick} type="button">
    {value}
  </Button>
);

interface Props {
  variant: string;
}

export const ProgressDatePicker = ({ variant }: Props) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<any>(new Date());
  const [startDateRange, setStartDateRange] = useState<any>(new Date());
  const [endDateRange, setEndDateRange] = useState<any>(new Date());
  const onChangeRange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDateRange(start);
    setEndDateRange(end);
  };

  return (
    <>
      {variant === 'Week' ? (
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            dispatch(setProgressDate(date?.toDateString()));
            setStartDate(date);
          }}
          showFullMonthYearPicker
          locale="pl"
          customInput={<CustomInput />}
          dateFormat="dd/MM/yy"
        />
      ) : null}
      {variant === 'Month' ? (
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
            dispatch(setProgressDate(date?.toDateString()));
          }}
          onMonthChange={(date) => dispatch(setProgressDate(date?.toDateString()))}
          dateFormat="MM/yyyy"
          showFullMonthYearPicker
          locale="pl"
          customInput={<CustomInput />}
        />
      ) : null}
      {variant === 'Range' ? (
        <DatePicker
          onChange={(date) => {
            const [start, end] = date;
            dispatch(
              setProgressDate([start?.toDateString() as string, end?.toDateString() as string]),
            );
            onChangeRange([start, end]);
          }}
          startDate={startDateRange}
          endDate={endDateRange}
          showFullMonthYearPicker
          locale="pl"
          selectsRange
          customInput={<CustomInput />}
          dateFormat="dd/MM/yy"
        />
      ) : null}
    </>
  );
};
