import { Grid } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { __ } from '../../../helpers/i18n';

interface Props {
  children: ReactNode;
  text: string;
}

export const LabeledIcon = ({ children, text }: Props) => (
  <Grid
    templateColumns={['20px 1fr', null, null, '32px 1fr']}
    fontSize={[12, 16, null, 22]}
    alignItems="center"
  >
    {children} {__(text)}
  </Grid>
);
