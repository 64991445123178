import { Flex, Image } from '@chakra-ui/react';
import { Text } from '../Text';

interface Props {
  image: any;
  text: string;
}

export const DashboardStepProgressBar = ({ image, text }: Props) => (
  <>
    <Text variant="BlueHeader">{text}</Text>
    <Flex style={{ maxWidth: '100px' }}>
      <Image src={image()} />
    </Flex>
  </>
);
