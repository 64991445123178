import { useContext, createContext, ReactElement } from 'react';
import { FormFieldContextType, ProviderType } from '../types/components';

const FormFieldContext = createContext<FormFieldContextType | null>(null);

const FormFieldProvider = ({
  children,
  value,
}: ProviderType<FormFieldContextType>): ReactElement => (
  <FormFieldContext.Provider value={value ?? null}>{children}</FormFieldContext.Provider>
);

const useFormFieldContext = (): FormFieldContextType => {
  const context = useContext(FormFieldContext);
  if (!context) {
    throw new Error('useFormFieldContext must be used within a FormFieldProvider');
  }
  return context;
};

export { FormFieldProvider, useFormFieldContext };
