import { Button, ButtonsContainer, Heading, Text } from '../../../components';
import { useModal } from '../../../hooks/useModal';

export const ResetPasswordReady = () => {
  const { onClose } = useModal();
  return (
    <>
      <Heading layerStyle="modalHeader" as="h1" text="header.ready" />
      <Text text="application.resetPasswordDescription" />
      <ButtonsContainer alignTo="right">
        <Button type="submit" text="action.login" onClick={onClose} />
      </ButtonsContainer>
    </>
  );
};
