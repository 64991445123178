import { Dispatch, SetStateAction } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { Step5 as PNG, Step5Content } from '../images/stepProgress/index';
import { MyModal } from '../common/MyModal';
import { DashboardStepProgressBar } from '../../components/common/DashboardStepProgressBar';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

export const Step5 = ({ setStep }: Props) => (
  <>
    <MyModal top="150px" left="550px">
      <Flex gap="20px" maxW="350px">
        <Flex direction="column" gap="30px" padding="20px 10px">
          <Flex gap="30px" align="center">
            <DashboardStepProgressBar text="5 z 6" image={PNG} />
          </Flex>
          <Text text="dashboardTutorial.step5" />
          <Flex gap="20px">
            <Button
              text="action.next"
              onClick={() => setStep((step: number) => step + 1)}
              variant="dasboardStep"
            />
          </Flex>
        </Flex>
      </Flex>
    </MyModal>
    <MyModal top="0px" left="800px" isOverlayed={false} isBackgroundTransparent>
      <Box>
        <Image src={Step5Content()} alt="alt.profile" maxW="90%" />
      </Box>
    </MyModal>
  </>
);
