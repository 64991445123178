import { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
  open: boolean;
  top?: string;
  left?: string;
  isCenter?: boolean;
  isOverlayed?: boolean;
  isBackgroundTransparent?: boolean;
  customBackgroundColor?: string;
}

interface ModalOverlayProps {
  customBackgroundColor?: string;
}

interface ModalStyleProp {
  top?: string;
  left?: string;
  isCenter?: boolean;
  isBackgroundTransparent?: boolean;
}

const ModalBodyWrapper = styled.div<ModalStyleProp>`
  position: fixed;
  top: ${({ isCenter, top }) => (isCenter ? '50%' : top)};
  left: ${({ isCenter, left }) => (isCenter ? '50%' : left)};
  transform: ${({ isCenter }) => (isCenter ? 'translate(-50%,-50%)' : 'none')};
  background-color: ${({ isBackgroundTransparent }) =>
    isBackgroundTransparent ? 'transparent' : '#FFFFFF'};
  border-radius: 10px;
  padding: ${({ isCenter }) => (isCenter ? '0px' : 0)};
  max-width: 100%;
  max-height: 100%;
  z-index: 1000;
`;

const ModalOverlay = styled.div<ModalOverlayProps>`
  position: fixed;
  inset: 0;
  background-color: ${({ customBackgroundColor }) =>
    customBackgroundColor || 'rgba(0, 0, 0, 0.25)'};
  z-index: 1000;
`;

export const ModalBody = ({
  children,
  open,
  top,
  left,
  isCenter,
  isOverlayed = true,
  isBackgroundTransparent,
  customBackgroundColor,
}: Props) =>
  // eslint-disable-next-line no-nested-ternary
  open ? (
    isOverlayed ? (
      <ModalOverlay customBackgroundColor={customBackgroundColor}>
        <ModalBodyWrapper
          top={top}
          left={left}
          isCenter={isCenter}
          isBackgroundTransparent={isBackgroundTransparent}
        >
          {children}
        </ModalBodyWrapper>
      </ModalOverlay>
    ) : (
      <ModalBodyWrapper
        top={top}
        left={left}
        isCenter={isCenter}
        isBackgroundTransparent={isBackgroundTransparent}
      >
        {children}
      </ModalBodyWrapper>
    )
  ) : null;
