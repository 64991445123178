import { Fade, Flex, Image } from '@chakra-ui/react';
import { diodePhoto, handPhoto, headbandPhoto } from '../images';
import { __ } from '../../helpers/i18n';
import { BackgroundWrapper } from './index';
import { HeadbandStartStep } from '../../types/components';

const isDiodeActivated = (step: HeadbandStartStep) =>
  step === HeadbandStartStep.ButtonActivated || step === HeadbandStartStep.DiodeActivated;

export const HeadbandStartBg = ({ step }: { step: HeadbandStartStep }) => (
  <Flex>
    <Fade transition={{ enter: { duration: 0.4 } }} in={isDiodeActivated(step)}>
      <Image
        position="absolute"
        left="525px"
        transition="0.5s"
        top="290px"
        src={diodePhoto()}
        alt={__('alt.sidebarPhoto')}
      />
    </Fade>
    <Image
      position="absolute"
      left="140px"
      top="220px"
      src={handPhoto()}
      alt={__('alt.sidebarPhoto')}
      w="35%"
    />
    <Image
      position="absolute"
      left="560px"
      top="330px"
      src={headbandPhoto()}
      alt={__('alt.sidebarPhoto')}
      w="26%"
    />
  </Flex>
);
