export enum GameType {
  Fish = 'fish',
  Apple = 'apple',
  Boat = 'boat',
  IceCream = 'iceCream',
  Shell = 'shell',
  Tree = 'tree',
  MushroomsPicking = 'mushromPicking',
  GreenMeadow = 'greenMeadow',
  FlowerPicking = 'flowerPicking',
}

export enum GameNames {
  Fish = 'Łowienie ryb',
  Boat = 'Pływanie łódką',
  IceCream = 'Robienie lodów',
  MushroomsPicking = 'Zbieranie grzybów',
  GreenMeadow = 'Zielona Łąka',
  FlowerPicking = 'Wiosenne Kwiaty',
  Tree = 'Rozkwitanie drzewa',
  Shell = 'Odkrywanie muszelek',
  Apple = 'Zbieranie jabłek',
}
