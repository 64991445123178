export interface Questionnaire {
  feel: QuestionnaireFeel;
  sleep: QuestionnaireSleep;
  after: QuestionnaireAfter;
}

export enum QuestionnaireFeel {
  Bad = 'BAD',
  Ok = 'OK',
  SoSo = 'SOSO',
  Great = 'GREAT',
}

export enum QuestionnaireSleep {
  LessThan5 = 'LESSTHAN5',
  Between5and7 = 'BETWEEN5AND7',
  Between7and9 = 'BETWEEN7AND9',
  MoreThan9 = 'MORETHAN9',
}

export enum QuestionnaireAfter {
  Stress = 'STRESS',
  Bored = 'BORED',
  Calm = 'CALM',
  excitement = 'EXCITEMENT',
}
