import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { SectionEnum } from '../types';
import { Message } from '../bluetooth/message';
import { getMillisecondsFromMessage, getTimeTickCorrection } from '../helpers/fitFactor/processEeg';
import { DataSectionEeg } from '../bluetooth/dataSections/dataSectionEeg';
import { DataSectionTmtck } from '../bluetooth/dataSections/dataSectionTmtck';
import { MeasurementTrackInformation } from '../bluetooth/measurementTrackInformation';

export interface EegSideMeasurements {
  timestamp?: number;

  eegSens0?: number; // O1  | L1
  eegSens1?: number; // T5  | L2
  eegSens2?: number; // T3  | L3
  eegSens3?: number; // F7  | L4
  eegSens4?: number; // Fp1 | L5

  eegSens5?: number; // O2  | R1
  eegSens6?: number; // T6  | R2
  eegSens7?: number; // T4  | R3
  eegSens8?: number; // F8  | R4
  eegSens9?: number; // Fp2 | R5
}

export interface HeadbandMeasurementState {
  headbandMeasurements: EegSideMeasurements[];
  previousEegTimeTick: number;
  tmtck: {
    ms: number;
    rtc: number;
    startTransmissionTimestamp: number;
  };
}

interface ActionData {
  message: Message;
  eegManager: MeasurementTrackInformation;
}

const initialState: HeadbandMeasurementState = {
  headbandMeasurements: [],
  previousEegTimeTick: 0,
  tmtck: { ms: 0, rtc: 0, startTransmissionTimestamp: 0 },
};

export const EEG_MEASUREMENT_SIDE: (keyof Omit<EegSideMeasurements, 'timestamp'>)[] = [
  'eegSens0',
  'eegSens1',
  'eegSens2',
  'eegSens3',
  'eegSens4',
  'eegSens5',
  'eegSens6',
  'eegSens7',
  'eegSens8',
  'eegSens9',
];

const headbandMeasurementDataReducer = (
  state: Draft<HeadbandMeasurementState>,
  action: PayloadAction<ActionData>,
) => {
  const ESTIMATED_TRANSMISSION_TIME_OFFSET = 15;

  const {
    payload: { message, eegManager },
  } = action;

  const eegSections = message.getSections<DataSectionEeg>(SectionEnum.Eeg);
  const tmtck = message.getFirstSection<DataSectionTmtck>(SectionEnum.Tmtck);

  const headbandMeasurements: EegSideMeasurements[] = [];

  if (!tmtck || !eegSections) {
    throw new Error('Missing tmtck or eegSections, it should always be with Eeg section');
  }

  state.tmtck.ms = tmtck.getMillisecondsTimeTick();
  state.tmtck.rtc = +new Date() - ESTIMATED_TRANSMISSION_TIME_OFFSET;
  state.tmtck.startTransmissionTimestamp = state.tmtck.rtc - state.tmtck.ms;

  let sampleMeasurementMsOffset = getMillisecondsFromMessage(
    message,
    tmtck.getMillisecondsTimeTick(),
  );
  sampleMeasurementMsOffset = getTimeTickCorrection(
    sampleMeasurementMsOffset,
    state.previousEegTimeTick,
    eegManager,
  );
  state.previousEegTimeTick = sampleMeasurementMsOffset;

  for (let i = 0; i < eegManager.getSamplesCountInDataSection(); i += 1) {
    const timestamp =
      state.tmtck.startTransmissionTimestamp +
      sampleMeasurementMsOffset +
      i * eegManager.getMillisecondsIntervalBetweenSamples();
    headbandMeasurements.push({ timestamp });
  }

  eegSections.forEach((dse) => {
    if (!dse.hasSamples()) return;
    dse.samples.forEach((sample, index) => {
      headbandMeasurements[index][EEG_MEASUREMENT_SIDE[dse.chN]] = sample;
    });
  });

  state.headbandMeasurements.push(...headbandMeasurements);
  if (state.headbandMeasurements.length > 3500) {
    state.headbandMeasurements = state.headbandMeasurements.slice(-256);
  }
};

const reduceHeadbandMeasurementDataReducer = (state: Draft<HeadbandMeasurementState>) => {
  state.headbandMeasurements = state.headbandMeasurements.slice(-256);
};

const clearHeadbandMeasurementDataReducer = (state: Draft<HeadbandMeasurementState>) => {
  state.headbandMeasurements = [];
};

const headbandMeasurement = createSlice({
  name: 'headband',
  initialState,
  reducers: {
    addHeadbandMeasurementData: headbandMeasurementDataReducer,
    reducePartOfHeadbandMeasurementData: reduceHeadbandMeasurementDataReducer,
    clearPartOfHeadbandMeasurementData: clearHeadbandMeasurementDataReducer,
  },
});

export const {
  addHeadbandMeasurementData,
  reducePartOfHeadbandMeasurementData,
  clearPartOfHeadbandMeasurementData,
} = headbandMeasurement.actions;

export default headbandMeasurement.reducer;
