import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { axiosRequestConfigurationJson } from '../config/axios';
import { initializeAxios } from '../services/api';

import { handleError } from '../helpers/handleError';

export type ApiReqFnBasic = <T>(
  url: string,
  queryParams?: Record<string, string>,
  isFile?: boolean,
) => Promise<void | T>;
export type ApiReqFn = <T, K = undefined>(
  url: string,
  body?: K | undefined,
  queryParams?: Record<string, string>,
) => Promise<void | T>;
export type ApiReqPostPutFn = <T, K = undefined>(
  url: string,
  body?: K | undefined,
  queryParams?: Record<string, string>,
) => Promise<void | T>;
export type ApiReqDelete = <T>(url: string) => Promise<void | T>;

interface UseApi {
  inProgress: boolean;
  isError: boolean;
  get: ApiReqFnBasic;
  post: ApiReqPostPutFn;
  put: ApiReqPostPutFn;
  patch: ApiReqFn;
  remove: ApiReqDelete;
  postAsPlainText: ApiReqPostPutFn;
}

export type ApiPayload<T> = { payload: T };
interface ApiResponse<T> extends AxiosResponse<ApiPayload<T>> {
  isAxiosError?: boolean;
}

const axiosInstance = initializeAxios(axiosRequestConfigurationJson);
const returnPayload = <T>(res: ApiResponse<T>) => res.data?.payload;

export const useApi = (): UseApi => {
  const [inProgress, setInProgress] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(
    () => () => {
      setInProgress(false);
      setIsError(false);
    },
    [],
  );

  const get = useCallback(<T>(url: string, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .get<ApiPayload<T>>(url, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        return returnPayload<T>(res);
      })
      .catch((err) => {
        setInProgress(false);
        setIsError(true);
        handleError(err?.response);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  const post = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .post<ApiPayload<T>>(url, body, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        return res.data.payload;
      })
      .catch((err) => {
        setIsError(true);
        setInProgress(false);
        handleError(err?.response);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  // @It temporary because standard post does not allow change content-type
  const postAsPlainText = useCallback(
    <T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
      setInProgress(true);
      setIsError(false);
      return axiosInstance
        .post<ApiPayload<T>>(url, body, {
          params: queryParams,
          headers: {
            'content-type': 'text/plain',
          },
        })
        .then((res: ApiResponse<T>) => {
          if (res?.isAxiosError) throw res;
          return res.data.payload;
        })
        .catch((err) => {
          setIsError(true);
          setInProgress(false);
          handleError(err?.response);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [],
  );

  const put = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .put<ApiPayload<T>>(url, body, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        return res.data.payload;
      })
      .catch((err) => {
        setInProgress(false);
        setIsError(true);
        handleError(err?.response);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  const patch = useCallback(<T, K>(url: string, body?: K, queryParams?: Record<string, string>) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .patch<ApiPayload<T>>(url, body, { params: queryParams })
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        return res.data.payload;
      })
      .catch((err) => {
        setInProgress(false);
        setIsError(true);
        handleError(err?.response);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  // Since delete is reserved we use custom name here
  const remove = useCallback(<T>(url: string) => {
    setInProgress(true);
    setIsError(false);
    return axiosInstance
      .delete<ApiPayload<T>>(url)
      .then((res: ApiResponse<T>) => {
        if (res?.isAxiosError) throw res;
        return res.data.payload;
      })
      .catch((err) => {
        setInProgress(false);
        setIsError(true);
        handleError(err?.response);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, []);

  return {
    get,
    post,
    patch,
    put,
    remove,
    postAsPlainText,
    inProgress,
    isError,
  };
};
