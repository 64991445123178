import { Flex } from '@chakra-ui/react';

import { FormField, InputText, InputNumber, ButtonsContainer, Button } from '../..';

interface Props {
  onClose: () => void;
  deleteTraining: () => void;
}

export const EditTrainingForm = ({ onClose, deleteTraining }: Props) => {
  const test = [];

  return (
    <>
      <Flex gap="20px" justify="center">
        <FormField labelTxt="label.time" name="hours" placeholder="placeholder.time">
          <InputNumber />
        </FormField>
        <Flex mt="5">
          <p style={{ fontSize: '30px', fontWeight: 'bold' }}>:</p>
        </Flex>
        <FormField labelTxt="label.minutes" name="minutes" placeholder="placeholder.minutes">
          <InputNumber />
        </FormField>
        <FormField labelTxt="label.patient" name="patient">
          <InputText />
        </FormField>
      </Flex>

      <ButtonsContainer alignTo="right" isSpaced>
        <Button text="action.delete" onClick={() => deleteTraining()} />
        <Button text="action.close" variant="light" onClick={() => onClose()} />
        <Button type="submit" text="action.save" />
      </ButtonsContainer>
    </>
  );
};
