import { Navigate, Route, Routes } from 'react-router-dom';
import { AccountSetup } from '../../../views/Auth/AccountSetup';
import { urls } from '../../../config/url';

export const RouteActiveUser = () => (
  <Routes>
    <Route path="account-setup" element={<AccountSetup />} />
    <Route path="*" element={<Navigate to={urls.auth.accountSetup} replace />} />
  </Routes>
);
