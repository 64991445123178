import { FC } from 'react';
import { Progress } from '@chakra-ui/react';

interface ProgressBarProps {
  value: number;
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined;
  isAnimated?: boolean;
}

export const ProgressBar: FC<ProgressBarProps> = ({ size = 'sm', value, isAnimated }) => (
  <Progress size={size} value={value} hasStripe={isAnimated} isAnimated={isAnimated} />
);
