import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Text } from '../common';
import { RootState } from '../../reducers';
import { DefinedBatteryStatus } from './DefinedBatteryStatus';
import { UnknowBatteryStatusIcon } from '../images';
import { deepEqual } from '../../helpers/deepEqual';

interface Props {
  isWhite?: boolean;
}

export const BatteryStatus = ({ isWhite }: Props) => {
  const isConnected = useSelector(
    (state: RootState) => state.headband.connection.isConnected,
    deepEqual,
  );
  const isConfigured = useSelector(
    (state: RootState) => state.headband.connection.isConfigured,
    deepEqual,
  );
  const batteryStatus = useSelector((state: RootState) => state.headband.sts.battery, deepEqual);

  return (
    <Flex align="center" gap="5px">
      {!isConnected || !isConfigured ? (
        <>
          <UnknowBatteryStatusIcon w={7} h={7} />
          <Text text="batteryStatus.notConnected" variant={isWhite ? 'baseStyle' : 'whiteHeader'} />
        </>
      ) : (
        <DefinedBatteryStatus batteryStatus={batteryStatus} isWhite />
      )}
    </Flex>
  );
};

export * from './Box';
export * from './DefinedBatteryStatus';
