import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { openModal, closeModal } from '../reducers/modal';

export const useModal = () => {
  const { isOpen } = useSelector((state: RootState) => state.modal);
  const dispatch = useDispatch();
  const onOpen = () => dispatch(openModal());
  const onClose = () => dispatch(closeModal());

  return { isOpen, onOpen, onClose };
};
