import { z } from 'zod';
import { emailValidation, passwordValidation } from '../helpers/zod';

export const loginSchema = () =>
  z.object({
    email: emailValidation(),
    password: passwordValidation(),
  });

export type LoginType = z.TypeOf<ReturnType<typeof loginSchema>>;
