import { Flex } from '@chakra-ui/react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { format, setDefaultOptions } from 'date-fns';
import { useSelector } from 'react-redux';
import { pl } from 'date-fns/locale';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { axiosInstance } from '../../config/axios';
import { apiUrls } from '../../config/url';
import {
  MyModal,
  Text,
  FormWrapper,
  Heading,
  TrainingForm,
  EditTrainingForm,
} from '../../components';
import { RootState } from '../../reducers';
import { useModal } from '../../hooks/useModal';
import { TodoReq } from '../../types/todo';
import { todoAddSchema } from '../../formSchemas/todoAddSchema';

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 40px 20px;
  box-shadow: 2px 8px 16px rgba(158, 137, 131, 0.25), 6px 15px 40px rgba(158, 137, 131, 0.25);
  gap: 20px;
  max-width: 350px;
  min-width: 370px;
  border-radius: 10px;
`;

type singleTraningType = {
  id: string;
  patient: string;
  hours: string;
  minutes: string;
};

setDefaultOptions({ locale: pl });

const valuesDefault = {
  hours: '',
  minutes: '',
  patient: '',
};

export const TodayTrainingCard = () => {
  const { date } = useSelector((state: RootState) => state.choosedDate);
  const [data, setData] = useState<singleTraningType[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [choosenTraining, setChoosenTraining] = useState('');

  const { onOpen, onClose } = useModal();
  const methods = useForm<TodoReq>({
    resolver: zodResolver(todoAddSchema()),
  });

  type TimeType = {
    hours: number;
    minutes: number;
  };

  useEffect(() => {
    if (!date) return;
    getTodos();
  }, [date]);

  useEffect(() => {
    // seting value to form on edit
    const setEveryValue = (values = valuesDefault) => {
      Object.entries(valuesDefault).forEach(([key, value]) =>
        methods.setValue(key as keyof typeof valuesDefault, values[key as keyof typeof values]),
      );
    };

    if (!choosenTraining) {
      setEveryValue();
      return;
    }
    const found = data.find(({ id }) => id === choosenTraining) ?? valuesDefault;
    setEveryValue(found);
  }, [choosenTraining]);

  const submitHandler = async (data: TodoReq) => {
    const res = await axiosInstance.post(apiUrls.todos.addTodo, {
      hours: data.hours,
      minutes: data.minutes,
      patient: data.patient,
      day: date,
    });
    if (res) {
      setData((prevState) => [...prevState, res.data.payload]);
      onClose();
    }
  };

  const submitEditTraining = async (data: TodoReq) => {
    const res = await axiosInstance.put(apiUrls.todos.editTodo, {
      hours: data.hours,
      minutes: data.minutes,
      patient: data.patient,
      id: choosenTraining,
    });
    if (res) {
      getTodos();
      onClose();
    }
  };

  const editClickHandler = (id: string) => {
    setIsEdit(true);
    setChoosenTraining(id);
    onOpen();
  };

  const addTodo = () => {
    setIsEdit(false);
    setChoosenTraining('');
    onOpen();
  };

  const deleteTraining = async () => {
    const res = await axiosInstance.delete(apiUrls.todos.deleteTodo(choosenTraining));
    if (res) {
      onClose();
      getTodos();
    }
  };

  const getTodos = async () => {
    const res = await axiosInstance(
      apiUrls.todos.getTodosOfDay(new Date(date as string).toString()),
    );
    if (res?.data?.payload) {
      setData(res.data.payload);
    }
  };

  const addZero = ({ hours, minutes }: TimeType): string => {
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${hoursStr}:${minutesStr}`;
  };

  return (
    <Wrapper>
      <Text variant="dateParagraph" fontSize="20px">
        {format(new Date(date ?? ''), 'EEEE, dd MMMM')}
      </Text>
      <Flex direction="column" gap="20px">
        {data?.map((x: any) => (
          <Flex key={x.id} gap="10px">
            <Text variant="BlueHeader" fontSize="20px" onClick={() => editClickHandler(x.id)}>
              {addZero(x)} {x.patient}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Text variant="BlueHeader" fontSize="20px" onClick={() => addTodo()}>
        + dodaj trening
      </Text>
      <MyModal isCenter>
        <Flex gap="50px" width="700px" padding="20px 20px">
          <Flex direction="column" justify="space-evenly">
            <FormWrapper<TodoReq> methods={methods} submitHandler={submitHandler}>
              <Heading layerStyle="modalHeader" as="h1" text="Traning.AddTraining" />
              <TrainingForm onClose={onClose} />
            </FormWrapper>
          </Flex>
        </Flex>
      </MyModal>
      {isEdit && (
        <MyModal isCenter>
          <Flex gap="50px" width="700px" padding="20px 20px">
            <Flex direction="column" justify="space-evenly">
              <FormWrapper<TodoReq> methods={methods} submitHandler={submitEditTraining}>
                <Heading layerStyle="modalHeader" as="h1" text="Traning.EditTraining" />
                <EditTrainingForm onClose={onClose} deleteTraining={deleteTraining} />
              </FormWrapper>
            </Flex>
          </Flex>
        </MyModal>
      )}
    </Wrapper>
  );
};
