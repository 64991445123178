import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PatientsDeleteReq, PatientsReq, PatientsRes } from 'types';
import { axiosInstance } from '../config/axios';
import { ApiPayload } from '../hooks/useApi';
import { apiUrls } from '../config/url';

interface Patients {
  current: PatientsRes | null;
  patients: PatientsRes[];
  isLoading: boolean;
}

const initialState: Patients = {
  current: null,
  isLoading: false,
  patients: [],
};

export const getPatients = createAsyncThunk('get/patients/patients', async () => {
  try {
    const res = await axiosInstance.get<ApiPayload<Promise<PatientsRes[]>>>('/patients/patients/');
    return res?.data?.payload ?? [];
  } catch {
    return [];
  }
});

export const addPatient = createAsyncThunk('add/patients/patient', async (data: PatientsReq) => {
  try {
    const res = await axiosInstance.post<ApiPayload<Promise<PatientsRes>>>(
      apiUrls.patients.patient,
      data,
    );
    return res?.data?.payload;
  } catch {
    return undefined;
  }
});

export const editPatient = createAsyncThunk('edit/patients/patient', async (data: PatientsRes) => {
  try {
    const res = await axiosInstance.patch<ApiPayload<Promise<PatientsRes>>>(
      apiUrls.patients.patient,
      data,
    );
    return res?.data?.payload;
  } catch {
    return undefined;
  }
});

export const deletePatient = createAsyncThunk(
  'delete/patients/patient',
  async ({ id }: PatientsDeleteReq) => {
    try {
      const res = await axiosInstance.delete<ApiPayload<Promise<PatientsRes>>>(
        apiUrls.patients.patientId(id),
      );
      return res?.data?.payload;
    } catch {
      return undefined;
    }
  },
);

const patients = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setPatient: (state, action: PayloadAction<PatientsRes>) => {
      state.current = action.payload;
    },
    setIsFirstUsage: (state, action: PayloadAction<boolean>) => {
      if (state.current) state.current.isFirstUsage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get
    builder
      .addCase(getPatients.pending, (state) => {
        state.patients = [];
        state.isLoading = true;
      })
      .addCase(getPatients.fulfilled, (state, action: PayloadAction<PatientsRes[]>) => {
        state.patients = action.payload;
        state.isLoading = false;
      })
      .addCase(getPatients.rejected, (state) => {
        state.isLoading = false;
        state.patients = [];
      })
      // post
      .addCase(addPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPatient.fulfilled, (state, action: PayloadAction<PatientsRes | undefined>) => {
        if (action?.payload) state.patients = [...state.patients, action.payload];
        state.isLoading = false;
      })
      .addCase(addPatient.rejected, (state) => {
        state.isLoading = false;
      })
      // patch
      .addCase(editPatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editPatient.fulfilled, (state, action: PayloadAction<PatientsRes | undefined>) => {
        if (action?.payload?.id) {
          state.patients = [
            ...state.patients.filter((patient) => patient.id !== action.payload?.id),
            action.payload,
          ];
        }
        state.isLoading = false;
      })
      .addCase(editPatient.rejected, (state) => {
        state.isLoading = false;
      })
      // delete
      .addCase(deletePatient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePatient.fulfilled, (state, action: PayloadAction<PatientsRes | undefined>) => {
        state.isLoading = false;
        state.patients = state.patients.filter((patient) => patient.id !== action.payload?.id);
      })
      .addCase(deletePatient.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setPatient, setIsFirstUsage } = patients.actions;

export default patients.reducer;
