import { createStandaloneToast } from '@chakra-ui/react';
import { theme } from '../config/theme';
import { __ } from './i18n';

type toastStatuses = 'success' | 'error' | 'info' | 'warning';

interface Props {
  title: string;
  status: toastStatuses;
  duration?: number | null;
}

export const notification = ({ title, status, duration = 3000 }: Props): void => {
  const toast = createStandaloneToast({ theme });

  if (toast) {
    toast({
      title,
      status,
      isClosable: true,
      duration,
      position: 'bottom',
    });
  }
};

export const persistentNotification = (title: string, status: toastStatuses = 'info'): void => {
  const toast = createStandaloneToast({ theme });

  toast({
    title: __(title),
    status,
    isClosable: true,
    duration: null,
    position: 'bottom',
  });
};

export const successNotification = (title: string): void => {
  notification({
    title: __(title),
    status: 'success',
  });
};

export const errorNotification = (title: string): void => {
  notification({
    title: __(title),
    status: 'error',
  });
};
