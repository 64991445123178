import { Dispatch, SetStateAction } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { Step3 as PNG, Step3Content } from '../images/stepProgress/index';
import { MyModal } from '../common/MyModal';
import { DashboardStepProgressBar } from '../../components/common/DashboardStepProgressBar';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

export const Step3 = ({ setStep }: Props) => (
  <>
    <MyModal top="100px" left="550px">
      <Flex gap="20px" maxW="350px">
        <Flex direction="column" gap="30px" padding="20px 10px">
          <Flex gap="30px" align="center">
            <DashboardStepProgressBar text="3 z 6" image={PNG} />
          </Flex>
          <Text text="dashboardTutorial.step3" />
          <Flex gap="20px">
            <Button
              text="action.next"
              onClick={() => setStep((step: number) => step + 1)}
              variant="dasboardStep"
              rightIcon={<ChevronRightIcon w="7" h="7" />}
            />
          </Flex>
        </Flex>
      </Flex>
    </MyModal>
    <MyModal top="370px" left="70px" isOverlayed={false} isBackgroundTransparent>
      <Box maxW="100%">
        <Image src={Step3Content()} alt="alt.chart" w="1000px" />
      </Box>
    </MyModal>
  </>
);
