import { ChangeEvent, FC, useCallback, useState } from 'react';
import { Input, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { __ } from '../../../helpers/i18n';
import { useFormFieldContext } from '../../../hooks/useFormFieldContext';

type HandleChange = (
  event: ChangeEvent<HTMLInputElement>,
  field: ControllerRenderProps<FieldValues, string>,
) => Promise<void>;

interface Props {
  variant?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputPassword: FC<Props> = ({ variant = 'defaultVariant', onChange }) => {
  const { name, placeholder } = useFormFieldContext();
  const { control, watch } = useFormContext();
  const [isViewed, setViewed] = useState(false);

  const handleChange = useCallback<HandleChange>(async (event, field) => {
    const { target, ...restOfEvent } = event;
    const { value, ...restOfTarget } = target;
    const transformedEvent = {
      target: {
        value: value || null,
        ...restOfTarget,
      },
      ...restOfEvent,
    };
    onChange?.(event);
    field.onChange?.(transformedEvent);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        // dirty fix for floating label, sets placeholder - later used in css to float input
        <InputGroup placeholder={watch(name)}>
          <Input
            type={isViewed ? 'text' : 'password'}
            placeholder={placeholder ? __(placeholder) : ' '}
            value={field.value ?? ''}
            onChange={(e) => handleChange(e, field)}
            variant={variant}
            autoComplete={name}
          />
          <InputRightElement w="60px" h="100%">
            <Button variant="transparent" onClick={() => setViewed(!isViewed)}>
              {isViewed ? <IoMdEyeOff /> : <IoMdEye />}
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
    />
  );
};
