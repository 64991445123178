import { Grid, GridItem, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { MdClose } from 'react-icons/md';
import { __ } from '../../../helpers/i18n';
import { GridContainer, Heading } from '../../common';

interface FaqHeaderProps {
  onClose: () => void;
  text: string;
}

export const FaqHeader: FC<FaqHeaderProps> = ({ onClose, text }) => (
  <GridItem colSpan={2} rowSpan={1}>
    <GridContainer rows="repeat(2, 1fr)">
      <GridItem>
        <IconButton
          position="absolute"
          top="20px"
          right="20px"
          aria-label={__('action.close')}
          variant="lightIcon"
          size="lg"
          icon={<MdClose />}
          onClick={onClose}
        />
      </GridItem>
      <GridItem>
        <Heading text={text} as="h3" />
      </GridItem>
    </GridContainer>
  </GridItem>
);
