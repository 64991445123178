import { Button, ButtonsContainer, FormField, InputPassword } from '../..';
import { DefaultFormProps } from '../../../types/components';

export const SetPasswordForm = ({ isDisabled }: DefaultFormProps) => (
  <>
    <FormField isRequired labelTxt="label.password" name="password">
      <InputPassword />
    </FormField>
    <FormField isRequired labelTxt="label.repeatPassword" name="repeatPassword">
      <InputPassword />
    </FormField>
    <ButtonsContainer alignTo="right">
      <Button type="submit" text="action.confirm" isDisabled={isDisabled} />
    </ButtonsContainer>
  </>
);
