import { ChangeEvent, FC, useCallback } from 'react';
import { Input } from '@chakra-ui/react';
import { Controller, ControllerRenderProps, FieldValues, useFormContext } from 'react-hook-form';
import { __ } from '../../../helpers/i18n';
import { useFormFieldContext } from '../../../hooks/useFormFieldContext';

type HandleChange = (
  event: ChangeEvent<HTMLInputElement>,
  field: ControllerRenderProps<FieldValues, string>,
) => Promise<void>;

interface Props {
  variant?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const InputNumber: FC<Props> = ({ variant = 'defaultVariant', onChange }) => {
  const { name, placeholder } = useFormFieldContext();
  const { control } = useFormContext();

  const handleChange = useCallback<HandleChange>(async (event, field) => {
    const { target, ...restOfEvent } = event;
    const { value, ...restOfTarget } = target;
    const transformedEvent = {
      target: {
        value: value || null,
        ...restOfTarget,
      },
      ...restOfEvent,
    };
    onChange?.(event);
    field.onChange?.(transformedEvent);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          type="number"
          placeholder={placeholder ? __(placeholder) : ' '}
          value={field.value ?? ''}
          onChange={(e) => handleChange(e, field)}
          variant={variant}
        />
      )}
    />
  );
};
