import { Language } from 'types';

export interface RegisterUserResponse {
  id: string;
}

export enum UserRole {
  User = 'USER',
  Administrator = 'ADMINISTRATOR',
}
export interface UserEntity {
  id: string;
  email: string | null;
  password: string | null;
  login: string | null;
  role: UserRole;
  status: Status;
  language: Language | null;
  currentTokenId: string | null;
  mailActivationToken: string | null;
  resetPasswordToken: string | null;
  headbandNr: string | null;
  customName: string | null;
  patientsLimit: number;
}
export interface UserType {
  id: string;
  email: string | null;
  password: string | null;
  login: string | null;
  role: UserRole;
  status: Status;
  language: Language | null;
  currentTokenId: string | null;
  mailActivationToken: string | null;
  resetPasswordToken: string | null;
}
export interface UserDetails {
  id: string;
  email: string | null;
  login: string;
  role: UserRole;
  status: Status;
  patientsLimit: number;
}

export interface UserResponse {
  user: UserDetails;
}

export enum Status {
  Blocked = 'BLOCKED',
  Accepted = 'ACCEPTED',
  Setup = 'SETUP',
  Waiting = 'WAITING',
}

export interface UpdateUserReq {
  patientsLimit: number;
  headbandNr: string;
}

export type AdminUserRes = {
  email: string;
  headbandNr: string;
  customName: string;
  id: string;
};

export type AdminUserDetailsRes = {
  patientsLimit: number;
  headbandNr: string;
} & AdminUserRes;

export type AdminUserList = AdminUserRes[];
