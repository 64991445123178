import { FC, ReactNode } from 'react';
import { AbsoluteContainer, GridContainer } from '../../common';

interface FaqContainerProps {
  children: ReactNode;
}

export const FaqContainer: FC<FaqContainerProps> = ({ children }) => (
  <AbsoluteContainer w="100vw" h="100vh">
    <GridContainer layerStyles="faq" columns="repeat(2, 1fr)" rows="repeat(5, 1fr)" gap="10">
      {children}
    </GridContainer>
  </AbsoluteContainer>
);
