import { useFormContext, get } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Flex,
  Box,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FormFieldProvider } from '../../hooks/useFormFieldContext';
import { __ } from '../../helpers/i18n';

export interface FormFieldProps {
  name: string;
  labelTxt: string;
  id?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  helperTxt?: string;
  errorTxt?: string | null;
  placeholder?: string;
  children: ReactNode;
}

export const FormField = ({
  name,
  labelTxt,
  isRequired,
  helperTxt,
  errorTxt,
  placeholder,
  children,
  isDisabled,
  id,
}: FormFieldProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMsg = get(errors, name)?.message ?? (errorTxt && __(errorTxt));

  return (
    <Flex height="100%" my={4}>
      <FormFieldProvider value={{ name, placeholder, isRequired, isDisabled, id }}>
        <FormControl
          variant="floating"
          isDisabled={isDisabled}
          isRequired={isRequired}
          isInvalid={!!errorMsg}
        >
          {children}
          <FormLabel whiteSpace="pre-line" htmlFor={name}>
            {__(labelTxt)}
          </FormLabel>
          <Box role="status" opacity={isDisabled ? 0.5 : 1}>
            {helperTxt && !errorMsg && <FormHelperText role="note">{__(helperTxt)}</FormHelperText>}
            <FormErrorMessage role="alert">{errorMsg}</FormErrorMessage>
          </Box>
        </FormControl>
      </FormFieldProvider>
    </Flex>
  );
};

export * from './InputText';
export * from './InputPassword';
export * from './InputNumber';
