import { FormField, InputText, ButtonsContainer, Button } from '../..';
import { DefaultFormProps } from '../../../types/components';

export const UpdateAccountForm = ({
  isDisabled,
  onClose,
}: DefaultFormProps & { onClose: () => void }) => (
  <>
    <FormField isRequired labelTxt="label.headbandNr" name="headbandNr">
      <InputText />
    </FormField>
    <FormField isRequired labelTxt="label.patientsLimit" name="patientsLimit">
      <InputText />
    </FormField>
    <ButtonsContainer alignTo="space-between" isSpaced>
      <Button variant="light" onClick={onClose} text="action.cancel" isDisabled={isDisabled} />
      <Button type="submit" text="action.save" isDisabled={isDisabled} />
    </ButtonsContainer>
  </>
);
