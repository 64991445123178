/* eslint-disable no-param-reassign */
import { Grid, SystemProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface GridContainerProps {
  layerStyles?: string;
  columns?: SystemProps['gridTemplateColumns'];
  rows?: SystemProps['gridTemplateRows'];
  gap?: SystemProps['gap'];
  children?: ReactNode;
}

export const GridContainer: FC<GridContainerProps> = ({
  columns = 'repeat(1, 1fr)',
  rows = 'repeat(1, 1fr)',
  children,
  gap,
  layerStyles = '',
}) => (
  <Grid layerStyle={layerStyles} gap={gap} templateColumns={columns} templateRows={rows}>
    {children}
  </Grid>
);
