import { Dispatch, SetStateAction } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { Step1 as PNG, Step1Content } from '../images/stepProgress/index';
import { MyModal } from '../common/MyModal';
import { DashboardStepProgressBar } from '../../components/common/DashboardStepProgressBar';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

export const Step1 = ({ setStep }: Props) => (
  <>
    <MyModal top="260px" left="600px">
      <Flex direction="column" gap="30px" padding="20px 10px" maxW="300px">
        <Flex gap="30px" align="center">
          <DashboardStepProgressBar text="1 z 6" image={PNG} />
        </Flex>
        <Text text="dashboardTutorial.step1" />
        <Flex gap="20px">
          <Button
            text="action.next"
            onClick={() => setStep((step: number) => step + 1)}
            variant="dasboardStep"
            rightIcon={<ChevronRightIcon w="7" h="7" />}
          />
        </Flex>
      </Flex>
    </MyModal>
    <MyModal top="150px" left="30px" isOverlayed={false} isBackgroundTransparent>
      <Box maxW="100%">
        <Image src={Step1Content()} alt="alt.startTraning" w="579px" />
      </Box>
    </MyModal>
  </>
);
