export enum ApiErrorCode {
  OtherError,
  LoginIsTaken,
  BadCredentials,
  FormValidationError,
  EmailIsTaken,
  AccountIsAlreadySetupUp,
  AccountIsNotActivated,
  AccountIsBlocked,
  InvalidLang,
  UserDoesNotExist,
  TooMuchPatients,
  PatientDoesNotExist,
  TrainingDoesNotExist,
  PatientOrTrainigsNotExist,
  NoSuchObjectWithThatId,
  CannotDownloadFile,
  WrongMimeType,
  NoSuchObjectWithThisId,
  ErrorDuringLoadFiles,
  HeadbandNrIsUsed,
  TodoDoesNotExist,
  FaqTopicAlreadyExist,
  FaqQuestionAlreadyExist,
}
