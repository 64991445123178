import { createIcon } from '@chakra-ui/icons';
import Band from './band.png';
import Tablet from './tablet.png';

export const band = () => Band;
export const tablet = () => Tablet;

export const UnknowBatteryStatusIcon = createIcon({
  displayName: 'unknowBatteryStatusIcon',
  viewBox: '0 0 16 26',
  path: (
    <>
      <path
        fill="currentColor"
        d="M16 5.2L16 23.92C16 24.4716 15.7893 25.0007 15.4142 25.3908C15.0391 25.7809 14.5304 26 14 26L2 26C1.46957 26 0.96086 25.7809 0.585787 25.3908C0.210714 25.0007 -6.68063e-08 24.4717 -9.09197e-08 23.92L-9.09197e-07 5.2C-9.3331e-07 4.64835 0.210713 4.1193 0.585786 3.72922C0.960859 3.33914 1.46957 3.12 2 3.12L3 3.12L3 2.08C3 1.52835 3.21071 0.999294 3.58579 0.609218C3.96086 0.219143 4.46957 -1.95371e-07 5 -2.18557e-07L11 -4.80825e-07C11.5304 -5.04011e-07 12.0391 0.219142 12.4142 0.609218C12.7893 0.999294 13 1.52835 13 2.08L13 3.12L14 3.12C14.5304 3.12 15.0391 3.33914 15.4142 3.72922C15.7893 4.11929 16 4.64835 16 5.2ZM2 23.92L14 23.92L14 5.2L11 5.2L11 2.08L5 2.08L5 5.2L2 5.2L2 23.92Z"
      />
      <path
        fill="currentColor"
        d="M5.00024 12.5468C4.99872 12.5828 5.00453 12.6187 5.01731 12.6523C5.03009 12.6859 5.04956 12.7166 5.07453 12.7424C5.09951 12.7681 5.12945 12.7885 5.16253 12.8022C5.1956 12.8159 5.23111 12.8226 5.26687 12.822H6.17961C6.33229 12.822 6.45399 12.6961 6.4739 12.5434C6.57347 11.8126 7.07133 11.2801 7.95863 11.2801C8.71759 11.2801 9.41238 11.6622 9.41238 12.5813C9.41238 13.2888 8.9986 13.6141 8.34475 14.1087C7.60017 14.6535 7.01048 15.2897 7.05252 16.3224L7.05584 16.5642C7.057 16.6373 7.08666 16.707 7.1384 16.7582C7.19015 16.8095 7.25984 16.8382 7.33243 16.8382H8.22969C8.30304 16.8382 8.37339 16.8089 8.42526 16.7567C8.47713 16.7044 8.50627 16.6336 8.50627 16.5597V16.4427C8.50627 15.6428 8.80831 15.41 9.62369 14.7872C10.2975 14.2714 11 13.6988 11 12.4967C11 10.8133 9.58829 10 8.04271 10C6.64096 10 5.10534 10.6573 5.00024 12.5468ZM6.72283 18.9672C6.72283 19.5611 7.19303 20 7.84025 20C8.51402 20 8.97758 19.5611 8.97758 18.9672C8.97758 18.3523 8.51291 17.92 7.83914 17.92C7.19303 17.92 6.72283 18.3523 6.72283 18.9672Z"
        stroke="white"
        strokeWidth="0.4"
      />
    </>
  ),
});

export const LowBatteryStatusIcon = createIcon({
  displayName: 'lowBatteryStatusIcon',
  viewBox: '0 0 16 26',
  path: (
    <>
      <path
        fill="currentColor"
        d="M16 5.2L16 23.92C16 24.4716 15.7893 25.0007 15.4142 25.3908C15.0391 25.7809 14.5304 26 14 26L2 26C1.46957 26 0.96086 25.7809 0.585787 25.3908C0.210714 25.0007 -6.68063e-08 24.4717 -9.09197e-08 23.92L-9.09197e-07 5.2C-9.3331e-07 4.64835 0.210713 4.1193 0.585786 3.72922C0.960859 3.33914 1.46957 3.12 2 3.12L3 3.12L3 2.08C3 1.52835 3.21071 0.999294 3.58579 0.609218C3.96086 0.219143 4.46957 -1.95371e-07 5 -2.18557e-07L11 -4.80825e-07C11.5304 -5.04011e-07 12.0391 0.219142 12.4142 0.609218C12.7893 0.999294 13 1.52835 13 2.08L13 3.12L14 3.12C14.5304 3.12 15.0391 3.33914 15.4142 3.72922C15.7893 4.11929 16 4.64835 16 5.2ZM2 23.92L14 23.92L14 5.2L11 5.2L11 2.08L5 2.08L5 5.2L2 5.2L2 23.92Z"
      />
      <path fill="#FF000F" d="M4 19L12 19L12 22L4 22L4 19Z" />
    </>
  ),
});

export const HighBatteryStatusIcon = createIcon({
  displayName: 'HighBatteryStatusIcon',
  viewBox: '0 0 16 26',
  path: (
    <>
      <path
        d="M16 5.2L16 23.92C16 24.4716 15.7893 25.0007 15.4142 25.3908C15.0391 25.7809 14.5304 26 14 26L2 26C1.46957 26 0.96086 25.7809 0.585787 25.3908C0.210714 25.0007 -6.68063e-08 24.4717 -9.09197e-08 23.92L-9.09197e-07 5.2C-9.3331e-07 4.64835 0.210713 4.1193 0.585786 3.72922C0.960859 3.33914 1.46957 3.12 2 3.12L3 3.12L3 2.08C3 1.52835 3.21071 0.999294 3.58579 0.609218C3.96086 0.219143 4.46957 -1.95371e-07 5 -2.18557e-07L11 -4.80825e-07C11.5304 -5.04011e-07 12.0391 0.219142 12.4142 0.609218C12.7893 0.999294 13 1.52835 13 2.08L13 3.12L14 3.12C14.5304 3.12 15.0391 3.33914 15.4142 3.72922C15.7893 4.11929 16 4.64835 16 5.2ZM2 23.92L14 23.92L14 5.2L11 5.2L11 2.08L5 2.08L5 5.2L2 5.2L2 23.92Z"
        fill="currentColor"
      />
      <path fill="currentColor" d="M4 9L12 9L12 22L4 22L4 9Z" />
    </>
  ),
});

export const ProfileIcon = createIcon({
  displayName: 'profileIcon',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M12 12C13.5913 12 15.1174 11.3679 16.2426 10.2426C17.3679 9.11742 18 7.5913 18 6C18 4.4087 17.3679 2.88258 16.2426 1.75736C15.1174 0.632141 13.5913 0 12 0C10.4087 0 8.88258 0.632141 7.75736 1.75736C6.63214 2.88258 6 4.4087 6 6C6 7.5913 6.63214 9.11742 7.75736 10.2426C8.88258 11.3679 10.4087 12 12 12V12ZM16 6C16 7.06087 15.5786 8.07828 14.8284 8.82843C14.0783 9.57857 13.0609 10 12 10C10.9391 10 9.92172 9.57857 9.17157 8.82843C8.42143 8.07828 8 7.06087 8 6C8 4.93913 8.42143 3.92172 9.17157 3.17157C9.92172 2.42143 10.9391 2 12 2C13.0609 2 14.0783 2.42143 14.8284 3.17157C15.5786 3.92172 16 4.93913 16 6V6ZM24 22C24 24 22 24 22 24H2C2 24 0 24 0 22C0 20 2 14 12 14C22 14 24 20 24 22ZM22 21.992C21.998 21.5 21.692 20.02 20.336 18.664C19.032 17.36 16.578 16 12 16C7.42 16 4.968 17.36 3.664 18.664C2.308 20.02 2.004 21.5 2 21.992H22Z"
      fill="currentColor"
    />
  ),
});

export const FAQIcon = createIcon({
  displayName: 'faqIcon',
  viewBox: '0 0 26 26',
  path: (
    <>
      <path
        d="M13 23.5C10.2152 23.5 7.54451 22.3938 5.57538 20.4246C3.60625 18.4555 2.5 15.7848 2.5 13C2.5 10.2152 3.60625 7.54451 5.57538 5.57538C7.54451 3.60625 10.2152 2.5 13 2.5C15.7848 2.5 18.4555 3.60625 20.4246 5.57538C22.3938 7.54451 23.5 10.2152 23.5 13C23.5 15.7848 22.3938 18.4555 20.4246 20.4246C18.4555 22.3938 15.7848 23.5 13 23.5ZM13 25C16.1826 25 19.2348 23.7357 21.4853 21.4853C23.7357 19.2348 25 16.1826 25 13C25 9.8174 23.7357 6.76516 21.4853 4.51472C19.2348 2.26428 16.1826 1 13 1C9.8174 1 6.76516 2.26428 4.51472 4.51472C2.26428 6.76516 1 9.8174 1 13C1 16.1826 2.26428 19.2348 4.51472 21.4853C6.76516 23.7357 9.8174 25 13 25V25Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M9.00031 9.56551C8.99829 9.61588 9.00604 9.66615 9.02308 9.71324C9.04011 9.76033 9.06608 9.80323 9.09938 9.83931C9.13268 9.8754 9.1726 9.9039 9.2167 9.92306C9.26081 9.94223 9.30815 9.95165 9.35582 9.95076H10.5728C10.7764 9.95076 10.9386 9.77451 10.9652 9.56083C11.098 8.53766 11.7618 7.79211 12.9448 7.79211C13.9568 7.79211 14.8832 8.32709 14.8832 9.61386C14.8832 10.6043 14.3315 11.0597 13.4597 11.7522C12.4669 12.5149 11.6806 13.4055 11.7367 14.8514L11.7411 15.1898C11.7427 15.2922 11.7822 15.3898 11.8512 15.4615C11.9202 15.5333 12.0131 15.5735 12.1099 15.5735H13.3062C13.4041 15.5735 13.4979 15.5324 13.567 15.4593C13.6362 15.3862 13.675 15.287 13.675 15.1836V15.0198C13.675 13.9 14.0777 13.574 15.1649 12.7021C16.0633 11.9799 17 11.1783 17 9.49532C17 7.13859 15.1177 6 13.0569 6C11.1879 6 9.14045 6.92023 9.00031 9.56551ZM11.2971 18.5541C11.2971 19.3855 11.924 20 12.787 20C13.6854 20 14.3034 19.3855 14.3034 18.5541C14.3034 17.6932 13.6839 17.088 12.7855 17.088C11.924 17.088 11.2971 17.6932 11.2971 18.5541Z"
        fill="currentColor"
      />
    </>
  ),
});
export const arrowRightIcon = createIcon({
  displayName: 'arrowRight',
  viewBox: '0 0 23 24',
  path: (
    <path
      d="M6.89985 17.7498L12.6499 11.9998L6.89986 6.2498L8.04986 3.9498L16.0999 11.9998L8.04985 20.0498L6.89985 17.7498Z"
      fill="white"
    />
  ),
});

export const SuccessIcon = createIcon({
  displayName: 'retryIcon',
  viewBox: '0 0 51 41',
  path: (
    <path
      d="M6 20.5L20.625 35L45 6"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="square"
    />
  ),
});

export const UnConnctedDotIcon = createIcon({
  displayName: 'ConnctedDotIcon',
  viewBox: '0 0 27 26',
  path: (
    <path
      d="M22.6717 3.78305C17.6152 -1.26102 9.31723 -1.26102 4.26068 3.78305C-0.795876 8.82711 -0.795876 17.1045 4.26068 22.1486C9.31723 27.1927 17.4855 27.1927 22.5421 22.1486C27.5986 17.1045 27.7283 8.82711 22.6717 3.78305ZM17.0965 18.3979L13.4662 14.7765L9.83585 18.3979L8.02068 16.5872L11.651 12.9658L8.02068 9.34445L9.83585 7.53376L13.4662 11.1551L17.0965 7.53376L18.9117 9.34445L15.2814 12.9658L18.9117 16.5872L17.0965 18.3979Z"
      fill="#C4C4C4"
    />
  ),
});

export const ConnctedDotIcon = createIcon({
  displayName: 'UnConnctedDotIcon',
  viewBox: '0 0 27 27',
  path: (
    <path
      d="M26.928 13.1983C26.928 16.637 25.562 19.9349 23.1304 22.3665C20.6988 24.798 17.4009 26.1641 13.9622 26.1641C10.5234 26.1641 7.22551 24.798 4.79394 22.3665C2.36238 19.9349 0.996338 16.637 0.996338 13.1983C0.996338 9.7595 2.36238 6.46159 4.79394 4.03003C7.22551 1.59846 10.5234 0.232422 13.9622 0.232422C17.4009 0.232422 20.6988 1.59846 23.1304 4.03003C25.562 6.46159 26.928 9.7595 26.928 13.1983ZM20.4937 8.28744C20.3779 8.17208 20.2401 8.08124 20.0884 8.02037C19.9367 7.9595 19.7743 7.92984 19.6109 7.93317C19.4475 7.9365 19.2865 7.97275 19.1374 8.03974C18.9883 8.10674 18.8543 8.20311 18.7433 8.3231L13.1145 15.4948L9.72234 12.101C9.49191 11.8863 9.18714 11.7694 8.87223 11.775C8.55732 11.7805 8.25686 11.9081 8.03415 12.1308C7.81145 12.3535 7.68387 12.654 7.67832 12.9689C7.67276 13.2838 7.78965 13.5886 8.00437 13.819L12.2928 18.1091C12.4083 18.2244 12.5459 18.3152 12.6973 18.3762C12.8487 18.4372 13.0109 18.4671 13.1741 18.4641C13.3373 18.4611 13.4982 18.4252 13.6473 18.3586C13.7963 18.2921 13.9304 18.1962 14.0416 18.0766L20.5115 9.98921C20.7321 9.75987 20.854 9.45319 20.8509 9.13502C20.8479 8.81684 20.7202 8.51254 20.4953 8.28744H20.4937Z"
      fill="#72EBAB"
    />
  ),
});
export const BadFeelIcon = createIcon({
  displayName: 'BadFeelIcon',
  viewBox: '0 0 131 131',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M65.5 131C101.675 131 131 101.675 131 65.5C131 29.3254 101.675 0 65.5 0C29.3253 0 0 29.3254 0 65.5C0 101.675 29.3253 131 65.5 131Z"
        fill="currentColor"
      />
      <path
        d="M47.4799 49.2C50.2965 49.2 52.5799 46.9167 52.5799 44.1C52.5799 41.2833 50.2965 39 47.4799 39C44.6632 39 42.3799 41.2833 42.3799 44.1C42.3799 46.9167 44.6632 49.2 47.4799 49.2Z"
        fill="#1A1F5A"
      />
      <path
        d="M82.9099 49.43C85.7265 49.43 88.0099 47.1466 88.0099 44.33C88.0099 41.5133 85.7265 39.23 82.9099 39.23C80.0932 39.23 77.8099 41.5133 77.8099 44.33C77.8099 47.1466 80.0932 49.43 82.9099 49.43Z"
        fill="#1A1F5A"
      />
      <path
        d="M97.09 92.2697C91.78 79.8997 79.49 71.2197 65.2 71.2197C50.91 71.2197 38.62 79.9097 33.31 92.2697H29C34.52 77.6497 48.66 67.2197 65.2 67.2197C81.74 67.2197 95.87 77.6497 101.4 92.2697H97.09V92.2697Z"
        fill="#1A1F5A"
      />
    </>
  ),
});
export const SoSoFeelIcon = createIcon({
  displayName: 'SoSoFeelIcon',
  viewBox: '0 0 131 131',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M65.5 131C101.675 131 131 101.675 131 65.5C131 29.3254 101.675 0 65.5 0C29.3253 0 0 29.3254 0 65.5C0 101.675 29.3253 131 65.5 131Z"
        fill="currentColor"
      />
      <path d="M35 81.8999H96.9" stroke="#1A1F5A" strokeWidth="4" strokeMiterlimit="10" />
      <path
        d="M48.2397 54.2C51.0563 54.2 53.3396 51.9167 53.3396 49.1C53.3396 46.2833 51.0563 44 48.2397 44C45.423 44 43.1396 46.2833 43.1396 49.1C43.1396 51.9167 45.423 54.2 48.2397 54.2Z"
        fill="#1A1F5A"
      />
      <path
        d="M83.6597 54.43C86.4763 54.43 88.7597 52.1466 88.7597 49.33C88.7597 46.5133 86.4763 44.23 83.6597 44.23C80.843 44.23 78.5596 46.5133 78.5596 49.33C78.5596 52.1466 80.843 54.43 83.6597 54.43Z"
        fill="#1A1F5A"
      />
    </>
  ),
});
export const OkFeelIcon = createIcon({
  displayName: 'OkFeelIcon',
  viewBox: '0 0 131 131',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M65.5 131C101.675 131 131 101.675 131 65.5C131 29.3254 101.675 0 65.5 0C29.3253 0 0 29.3254 0 65.5C0 101.675 29.3253 131 65.5 131Z"
        fill="currentColor"
      />
      <path
        d="M47.4804 51.2C50.297 51.2 52.5804 48.9167 52.5804 46.1C52.5804 43.2833 50.297 41 47.4804 41C44.6637 41 42.3804 43.2833 42.3804 46.1C42.3804 48.9167 44.6637 51.2 47.4804 51.2Z"
        fill="#1A1F5A"
      />
      <path
        d="M82.9104 51.43C85.727 51.43 88.0104 49.1467 88.0104 46.33C88.0104 43.5134 85.727 41.23 82.9104 41.23C80.0937 41.23 77.8104 43.5134 77.8104 46.33C77.8104 49.1467 80.0937 51.43 82.9104 51.43Z"
        fill="#1A1F5A"
      />
      <path
        d="M97.09 68.73C91.78 81.1 79.49 89.78 65.2 89.78C50.91 89.78 38.62 81.09 33.31 68.73H29C34.52 83.35 48.66 93.78 65.2 93.78C81.74 93.78 95.87 83.35 101.4 68.73H97.09V68.73Z"
        fill="#1A1F5A"
      />
    </>
  ),
});
export const GreatFeelIcon = createIcon({
  displayName: 'GreatFeelIcon',
  viewBox: '0 0 131 131',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M65.5 131C101.675 131 131 101.675 131 65.5C131 29.3254 101.675 0 65.5 0C29.3253 0 0 29.3254 0 65.5C0 101.675 29.3253 131 65.5 131Z"
        fill="currentColor"
      />
      <path
        d="M96.9 70.8398C96.9 85.1198 83.04 96.6898 65.95 96.6898C48.86 96.6898 35 85.1198 35 70.8398H96.9Z"
        fill="#1A1F5A"
      />
      <path
        d="M48.2299 54.2C51.0465 54.2 53.3299 51.9167 53.3299 49.1C53.3299 46.2833 51.0465 44 48.2299 44C45.4132 44 43.1299 46.2833 43.1299 49.1C43.1299 51.9167 45.4132 54.2 48.2299 54.2Z"
        fill="#1A1F5A"
      />
      <path
        d="M83.6599 54.43C86.4765 54.43 88.7599 52.1467 88.7599 49.33C88.7599 46.5134 86.4765 44.23 83.6599 44.23C80.8432 44.23 78.5599 46.5134 78.5599 49.33C78.5599 52.1467 80.8432 54.43 83.6599 54.43Z"
        fill="#1A1F5A"
      />
    </>
  ),
});

export const StressFeelIcon = createIcon({
  displayName: 'StressFeelIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="currentColor"
      />
      <path
        d="M47.5926 50.5123C50.3714 50.5123 52.6241 48.2596 52.6241 45.4808C52.6241 42.7019 50.3714 40.4492 47.5926 40.4492C44.8137 40.4492 42.561 42.7019 42.561 45.4808C42.561 48.2596 44.8137 50.5123 47.5926 50.5123Z"
        fill="#1A1F5A"
      />
      <path
        d="M82.5468 50.7485C85.3257 50.7485 87.5784 48.4958 87.5784 45.717C87.5784 42.9381 85.3257 40.6854 82.5468 40.6854C79.768 40.6854 77.5153 42.9381 77.5153 45.717C77.5153 48.4958 79.768 50.7485 82.5468 50.7485Z"
        fill="#1A1F5A"
      />
      <path
        d="M95.5994 90.0362C95.5994 75.9479 81.9254 64.5332 65.0648 64.5332C48.2042 64.5332 34.5303 75.9578 34.5303 90.0362H95.5994Z"
        fill="#1A1F5A"
      />
    </>
  ),
});

export const BoredFeelIcon = createIcon({
  displayName: 'BoredFeelIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="currentColor"
      />
      <path
        d="M57.7147 98.659C61.2563 98.659 64.1274 95.7879 64.1274 92.2462C64.1274 88.7045 61.2563 85.8335 57.7147 85.8335C54.173 85.8335 51.3019 88.7045 51.3019 92.2462C51.3019 95.7879 54.173 98.659 57.7147 98.659Z"
        fill="#1A1F5A"
      />
      <path
        d="M43.4092 51.3031C43.4092 54.0852 45.6586 56.3346 48.4407 56.3346C51.2229 56.3346 53.4723 54.0852 53.4723 51.3031"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M73.8356 51.3027C73.8356 54.0849 76.085 56.3343 78.8671 56.3343C81.6492 56.3343 83.8987 54.0849 83.8987 51.3027"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </>
  ),
});

export const CalmFeelIcon = createIcon({
  displayName: 'CalmFeelIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="currentColor"
      />
      <path
        d="M95.7869 71.1633C90.5482 83.3672 78.4232 91.9307 64.325 91.9307C50.2268 91.9307 38.0919 83.3672 32.863 71.1633H28.6109C34.0568 85.587 48.007 95.877 64.325 95.877C80.643 95.877 94.5833 85.587 100.039 71.1633H95.7869V71.1633Z"
        fill="#1A1F5A"
      />
      <path
        d="M44.0704 49.3301C44.0704 52.1122 46.3198 54.3616 49.1019 54.3616C51.8841 54.3616 54.1335 52.1122 54.1335 49.3301"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
      <path
        d="M74.5063 49.3301C74.5063 52.1122 76.7557 54.3616 79.5378 54.3616C82.32 54.3616 84.5694 52.1122 84.5694 49.3301"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeMiterlimit="10"
      />
    </>
  ),
});

export const TimerIcon = createIcon({
  displayName: 'TimerIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="currentColor"
      />
      <path
        d="M65 98C83.2254 98 98 83.2254 98 65C98 46.7746 83.2254 32 65 32C46.7746 32 32 46.7746 32 65C32 83.2254 46.7746 98 65 98Z"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path d="M64 42.5L64 68.2807L78 82" stroke="#1A1F5A" strokeWidth="4" />
    </>
  ),
});

export const MushroomIcon = createIcon({
  displayName: 'MushroomIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="#D4D6F4"
      />
      <path
        d="M58.25 65V89.1111C58.25 90.9382 58.9612 92.6904 60.227 93.9823C61.4929 95.2742 63.2098 96 65 96C66.7902 96 68.5071 95.2742 69.773 93.9823C71.0388 92.6904 71.75 90.9382 71.75 89.1111V65M92 61.9C92 46.4896 79.9108 34 65 34C50.0892 34 38 46.4896 38 61.9C38 62.7222 38.32 63.5107 38.8897 64.092C39.4593 64.6734 40.2319 65 41.0375 65H88.9625C89.7681 65 90.5407 64.6734 91.1103 64.092C91.68 63.5107 92 62.7222 92 61.9V61.9Z"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const AppleIcon = createIcon({
  displayName: 'AppleIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="#D4D6F4"
      />
      <path
        d="M58.4824 33.249C63.3451 34.9462 65.6707 40.9037 65.6707 40.9037C65.6707 40.9037 60.1033 44.0968 55.2406 42.3997C50.3779 40.7025 48.0522 34.745 48.0522 34.745C48.0522 34.745 53.6197 31.5519 58.4824 33.249V33.249Z"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.4892 34.7012C65.4591 39.6614 63.8206 45.1641 64.7808 51.0779"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.9416 92.7283C58.3326 98.7558 70.7007 98.7558 78.0917 92.7283C85.1567 86.972 91.4113 77.5676 91.746 65.4688C91.975 57.053 85.4562 49.5733 76.9905 49.3371C74.0659 49.2496 71.3262 49.9845 68.9741 51.3229C66.2168 52.8889 62.8077 52.8889 60.0592 51.3229C57.7159 49.9845 54.9674 49.2584 52.0428 49.3371C43.5683 49.582 37.0495 57.053 37.2873 65.4688C37.6221 77.5764 43.8766 86.972 50.9416 92.7283V92.7283Z"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.3431 61.8105C45.0393 67.5056 46.3431 73.2095 50.2455 78.9046"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const IceCreamIcon = createIcon({
  displayName: 'IceCreamIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="#D4D6F4"
      />
      <g clipPath="url(#clip0_102_16)">
        <path
          d="M82.7631 60.2301H47.0543C44.2835 53.1056 48.4353 39.0397 62.6429 39.0397C71.6025 39.0397 63.4629 26.7528 63.4629 26.7528C72.4829 26.7528 87.9076 43.4958 82.7631 60.2388V60.2301Z"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.7631 60.2301L64.9216 106.256L47.0543 60.2301H82.7631Z"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.7724 70.8941L63.5864 80.6588"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_102_16">
          <rect width="41" height="83" fill="white" transform="translate(44.5166 25)" />
        </clipPath>
      </defs>
    </>
  ),
});

export const FishIcon = createIcon({
  displayName: 'FishIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="#D4D6F4"
      />
      <path
        d="M28.5166 50.4032L51.4841 71.9252C64.8613 82.6514 83.8798 82.5507 97.1487 71.6927L97.3342 71.5377C101.415 68.1974 101.461 61.9586 97.427 58.5563C84.0807 47.2876 64.614 47.1326 51.09 58.192L28.5166 79.8071V50.4032Z"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.26 64.36C86.8404 64.36 87.311 63.8881 87.311 63.306C87.311 62.7239 86.8404 62.252 86.26 62.252C85.6795 62.252 85.209 62.7239 85.209 63.306C85.209 63.8881 85.6795 64.36 86.26 64.36Z"
        fill="#1A1F5A"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const LevelIcon = createIcon({
  displayName: 'LevelIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="#D4D6F4"
      />
      <path
        d="M32.5166 34V95H93.5166"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.3681 64.2317V55H79.1364"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5166 84.9319L50.3806 64.127L61.8806 79.127L88.3681 55"
        stroke="#1A1F5A"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export const DotsMenuIcon = createIcon({
  displayName: 'trilpeDot',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M7.75 12C7.75 12.2298 7.70474 12.4574 7.61679 12.6697C7.52884 12.882 7.39994 13.0749 7.23744 13.2374C7.07493 13.3999 6.88202 13.5288 6.6697 13.6168C6.45738 13.7047 6.22981 13.75 6 13.75C5.77019 13.75 5.54262 13.7047 5.3303 13.6168C5.11798 13.5288 4.92507 13.3999 4.76256 13.2374C4.60006 13.0749 4.47116 12.882 4.38321 12.6697C4.29526 12.4574 4.25 12.2298 4.25 12C4.25 11.5359 4.43437 11.0908 4.76256 10.7626C5.09075 10.4344 5.53587 10.25 6 10.25C6.46413 10.25 6.90925 10.4344 7.23744 10.7626C7.56563 11.0908 7.75 11.5359 7.75 12V12ZM13.75 12C13.75 12.4641 13.5656 12.9092 13.2374 13.2374C12.9092 13.5656 12.4641 13.75 12 13.75C11.5359 13.75 11.0908 13.5656 10.7626 13.2374C10.4344 12.9092 10.25 12.4641 10.25 12C10.25 11.5359 10.4344 11.0908 10.7626 10.7626C11.0908 10.4344 11.5359 10.25 12 10.25C12.4641 10.25 12.9092 10.4344 13.2374 10.7626C13.5656 11.0908 13.75 11.5359 13.75 12V12ZM18 13.75C18.4641 13.75 18.9092 13.5656 19.2374 13.2374C19.5656 12.9092 19.75 12.4641 19.75 12C19.75 11.5359 19.5656 11.0908 19.2374 10.7626C18.9092 10.4344 18.4641 10.25 18 10.25C17.5359 10.25 17.0908 10.4344 16.7626 10.7626C16.4344 11.0908 16.25 11.5359 16.25 12C16.25 12.4641 16.4344 12.9092 16.7626 13.2374C17.0908 13.5656 17.5359 13.75 18 13.75Z"
      fill="#1A1F5A"
    />
  ),
});

export const ShellIcon = createIcon({
  displayName: 'ShellIcon',
  viewBox: '0 0 130 130',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M64.6208 129.242C100.31 129.242 129.242 100.31 129.242 64.6208C129.242 28.9317 100.31 0 64.6208 0C28.9317 0 0 28.9317 0 64.6208C0 100.31 28.9317 129.242 64.6208 129.242Z"
        fill="#D4D6F4"
      />
      <g clipPath="url(#clip0_202_6)">
        <path
          d="M102.656 66.008L64.5352 100.095L26.3775 66.008C26.3775 17.7823 102.656 17.7823 102.656 66.008Z"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63.7443 98.8426L64.5352 100.159L65.3726 98.7874"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.377 99.4134L64.5259 100.095L64.6655 99.4134"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.3724 82.255V100.159H84.6608V82.255"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.6008 98.1523L66.331 98.7874"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M90.7925 40.519L74.6399 78.9872"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.7115 98.7874L62.4417 98.1339"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.4026 78.9872L38.2407 40.519"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.5166 31.1021V75.7286"
          stroke="#1A1F5A"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_202_6">
          <rect width="80" height="74" fill="white" transform="translate(24.5166 28)" />
        </clipPath>
      </defs>
    </>
  ),
});
