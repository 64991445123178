import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiOkResult, PasswordReset } from 'types';
import { useApi } from '../../../hooks/useApi';
import { errorNotification, successNotification } from '../../../helpers/notifications';
import { SetPasswordType, setPasswordSchema } from '../../../formSchemas/setPasswordSchema';
import { FormWrapper, Heading, SetPasswordForm } from '../../../components';
import { apiUrls, urls } from '../../../config/url';

export const SetPassword = () => {
  const { userId, tokenId } = useParams<{ userId: string; tokenId: string }>();

  const methods = useForm<SetPasswordType>({
    resolver: zodResolver(setPasswordSchema()),
  });
  const { post, inProgress } = useApi();
  const navigate = useNavigate();

  const submitHandler = async (formData: SetPasswordType) => {
    if (!userId || !tokenId) {
      errorNotification('apiErrorCode.0');
      return;
    }
    const res = await post<ApiOkResult, PasswordReset>(apiUrls.auth.setPassword(userId, tokenId), {
      password: formData.password,
    });
    if (res) {
      successNotification('notification.passwordReset');
      navigate(urls.auth.login);
    }
  };

  return (
    <FormWrapper<SetPasswordType> submitHandler={submitHandler} methods={methods}>
      <Heading as="h1" text="header.setPassword" />
      <SetPasswordForm isDisabled={inProgress} />
    </FormWrapper>
  );
};
