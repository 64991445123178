import { AiOutlineInfoCircle, AiOutlineRight } from 'react-icons/ai';
import { LabeledIcon, FormField, InputText, ButtonsContainer, Button, InputPassword } from '../..';
import { DefaultFormProps } from '../../../types/components';
import { urls } from '../../../config/url';

export const AccountSetupLoginForm = ({ isDisabled }: DefaultFormProps) => (
  <>
    <FormField isRequired labelTxt="label.login" name="login">
      <InputText />
    </FormField>
    <FormField isRequired labelTxt="label.password" name="password">
      <InputPassword />
    </FormField>
    <LabeledIcon text="label.firstLogin">
      <AiOutlineInfoCircle />
    </LabeledIcon>
    <ButtonsContainer alignTo="space-between" isSpaced>
      <Button
        variant="light"
        to={urls.auth.login}
        text="action.alreadyRegistered"
        isDisabled={isDisabled}
      />
      <Button
        type="submit"
        text="action.next"
        isDisabled={isDisabled}
        rightIcon={<AiOutlineRight />}
      />
    </ButtonsContainer>
  </>
);
