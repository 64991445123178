import { useLayoutEffect, useState, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ChakraProvider } from '@chakra-ui/react';
import { AppSpinner, AppRouting } from './components';
import { store, persistor } from './config/store';
import { i18n } from './helpers/i18n';
import { theme } from './config/theme';
import { setWindowState } from './helpers/windowState';
import { AppConfig } from './config/app';

import '@fontsource/work-sans/400.css'; // Normal
import '@fontsource/work-sans/500.css';
import '@fontsource/work-sans/600.css'; // Semi-Bold
import '@fontsource/work-sans/700.css'; // Bold

export const App = () => {
  const [i18nLoaded, setI18nLoaded] = useState(i18n.loaded);
  const reloadActiveTranslations = () => {
    setI18nLoaded(true);
  };

  useLayoutEffect(() => {
    const windowLanguage = window.navigator.language.slice(0, 2).toLowerCase();
    const language = AppConfig.LANGUAGES.find((lang: string) => lang === windowLanguage)
      ? windowLanguage
      : AppConfig.DEFAULT_LANG;
    i18n.load(language, reloadActiveTranslations);
  }, [setI18nLoaded]);

  return (
    <Provider store={store}>
      {/* TODO: Update chakra, when it works with React18 properly */}
      <ChakraProvider theme={theme} resetCSS>
        {i18nLoaded ? (
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <Suspense fallback={<AppSpinner fullScreen />}>
                <AppRouting />
              </Suspense>
            </BrowserRouter>
          </PersistGate>
        ) : (
          <AppSpinner fullScreen />
        )}
      </ChakraProvider>
    </Provider>
  );
};

setWindowState();
