import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { ModalBody } from './ModalBody';
import { useModal } from '../../../hooks/useModal';

interface Props {
  children: ReactNode;
  top?: string;
  left?: string;
  isCenter?: boolean;
  isOverlayed?: boolean;
  isBackgroundTransparent?: boolean;
  customBackgroundColor?: string;
}

export const MyModal = ({
  children,
  top,
  left,
  isCenter,
  isOverlayed,
  isBackgroundTransparent,
  customBackgroundColor,
}: Props) => {
  const { isOpen } = useModal();

  return ReactDOM.createPortal(
    <ModalBody
      open={isOpen}
      top={top}
      left={left}
      isCenter={isCenter}
      isOverlayed={isOverlayed}
      isBackgroundTransparent={isBackgroundTransparent}
      customBackgroundColor={customBackgroundColor}
    >
      {children}
    </ModalBody>,
    document.getElementById('portal') as HTMLElement,
  );
};
