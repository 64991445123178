import { LowBatteryStatusIcon, HighBatteryStatusIcon } from '../../images/icons';
import { Text } from '../../common/Text/index';

interface Props {
  batteryStatus: number;
  isWhite: boolean;
}

export const DefinedBatteryStatus = ({ batteryStatus, isWhite }: Props) => (
  <>
    {batteryStatus <= 30 ? (
      <LowBatteryStatusIcon w="7" h="7" />
    ) : (
      <HighBatteryStatusIcon w="7" h="7" />
    )}
    <Text text="batteryStatus.value" variant={isWhite ? 'blueHeader' : 'whiteHeader'}>
      {batteryStatus}
    </Text>
  </>
);
