import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ListOfTrainingFilesPerHeadband } from 'types';

interface FilesInitialState {
  filesPerHeadband: ListOfTrainingFilesPerHeadband;
}

const initialState: FilesInitialState = {
  filesPerHeadband: [],
};

const files = createSlice({
  name: 'files',
  initialState,
  reducers: {
    getFiles: (state, action: PayloadAction<ListOfTrainingFilesPerHeadband>) => {
      state.filesPerHeadband = action.payload;
    },
  },
});

export const { getFiles } = files.actions;

export default files.reducer;
