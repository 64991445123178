import Step1ContentPng from './step1content.png';
import Step2ContentPng from './step2content.png';
import Step3ContentPng from './step3content.png';
import Step4ContentPng from './step4content.png';
import Step5ContentPng from './step5content.png';
import Step6ContentPng from './step6content.png';
import Step1Png from './step1.png';
import Step2Png from './step2.png';
import Step3Png from './step3.png';
import Step4Png from './step4.png';
import Step5Png from './step5.png';
import Step6Png from './step6.png';

export const Step1 = () => Step1Png;
export const Step2 = () => Step2Png;
export const Step3 = () => Step3Png;
export const Step4 = () => Step4Png;
export const Step5 = () => Step5Png;
export const Step6 = () => Step6Png;
export const Step1Content = () => Step1ContentPng;
export const Step2Content = () => Step2ContentPng;
export const Step3Content = () => Step3ContentPng;
export const Step4Content = () => Step4ContentPng;
export const Step5Content = () => Step5ContentPng;
export const Step6Content = () => Step6ContentPng;
