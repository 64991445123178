import { FormField, InputText, ButtonsContainer, Button } from '../..';
import { DefaultFormProps } from '../../../types/components';

export const ResetPasswordForm = ({ isDisabled }: DefaultFormProps) => (
  <>
    <FormField isRequired labelTxt="label.email" name="email">
      <InputText />
    </FormField>
    <ButtonsContainer alignTo="right">
      <Button type="submit" text="action.send" isDisabled={isDisabled} />
    </ButtonsContainer>
  </>
);
