import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChoosedDateState {
  date: string | undefined;
  progressDate: string | undefined | [string | null, string | null];
}

const initialState: ChoosedDateState = {
  date: new Date().toDateString(),
  progressDate: new Date().toDateString(),
};

const choosedDate = createSlice({
  name: 'choosedDate',
  initialState,
  reducers: {
    setDate: (state: ChoosedDateState, { payload }: PayloadAction<string | undefined>) => {
      if (payload) {
        state.date = payload;
        return;
      }

      state.date = payload;
    },
    setProgressDate: (
      state: ChoosedDateState,
      { payload }: PayloadAction<string | undefined | [string, string]>,
    ) => {
      if (payload) {
        state.progressDate = payload;
        return;
      }

      state.date = payload;
    },
  },
});

export const { setDate, setProgressDate } = choosedDate.actions;

export default choosedDate.reducer;
