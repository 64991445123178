import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiOkResult } from 'types';
import { apiUrls } from '../../config/url';
import { useApi } from '../../hooks/useApi';
import { useModal } from '../../hooks/useModal';
import { Step0 } from './Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';
import { Step6 } from './Step6';
import { setIsFirstUsage } from '../../reducers/patients';
import { RootState } from '../../reducers';

export const DashboardIntroSteps = () => {
  const [step, setStep] = useState(0);
  const { isOpen, onOpen, onClose } = useModal();
  const { patch } = useApi();
  const dispatch = useDispatch();

  const isFirstUsage = useSelector((state: RootState) => state.patients.current?.isFirstUsage);
  const patientId = useSelector((state: RootState) => state.patients.current?.id);

  const handleTutorialFinish = async () => {
    const res = await patch<ApiOkResult, null>(apiUrls.patients.firstUsage(patientId as string));
    if (res) {
      dispatch(setIsFirstUsage(false));
    }
  };

  useEffect(() => {
    if (isFirstUsage) onOpen();
  }, []);

  useEffect(() => {
    if (step === 7) {
      onClose();
      handleTutorialFinish();
    }
  }, [step]);

  useEffect(() => {
    if (isOpen) setStep(0);
  }, [isOpen]);

  return (
    <>
      {step === 0 ? <Step0 setStep={setStep} skip={handleTutorialFinish} /> : null}
      {step === 1 ? <Step1 setStep={setStep} /> : null}
      {step === 2 ? <Step2 setStep={setStep} /> : null}
      {step === 3 ? <Step3 setStep={setStep} /> : null}
      {step === 4 ? <Step4 setStep={setStep} /> : null}
      {step === 5 ? <Step5 setStep={setStep} /> : null}
      {step === 6 ? <Step6 setStep={setStep} /> : null}
    </>
  );
};
