export const Text = {
  variants: {
    whiteHeader: {
      color: 'white',
      fontWeight: 600,
    },
    headbandStartDescription: {
      width: '40%',
    },
    BlueHeader: {
      color: 'main',
      fontWeight: 600,
    },
    baseStyle: {
      color: '#181d55',
      fontWeight: 400,
    },
    whiteParagraph: {
      color: 'white',
      fontWeight: 400,
    },
    blueParagraph: {
      color: 'main',
      fontWeight: 500,
    },
    dateParagraph: {
      fontWeight: 'bold',
      color: 'main',
      textTransform: 'capitalize',
    },
  },
};
