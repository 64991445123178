import styled, { keyframes } from 'styled-components';

interface Props {
  backgroundColor: string;
  start: string;
  top: string;
  left: string;
}

const connectAnimation = keyframes`
  0%, 100% {
    transform: scale(0.2);
    background-color: #00ff775a;
  }
  50% {
    transform: scale(1);
    background-color: #00ff77;
  }
`;

const StyledDot = styled.div<Props>`
  width: 20px;
  height: 20px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 50%;
  animation: ${connectAnimation} 1s ease-in-out infinite;
  animation-delay: ${({ start }) => start};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  position: absolute;
`;

export const Dot = ({ start, top, left, backgroundColor }: Props) => (
  <StyledDot start={start} top={top} left={left} backgroundColor={backgroundColor} />
);
