import { z } from 'zod';
import { __ } from '../helpers/i18n';

export const todoAddSchema = () =>
  z.object({
    hours: hoursValidation(),
    minutes: minutesValidation(),
    patient: patientValidation(),
  });

export const hoursValidation = ({ min = 0, max = 23 } = {}) =>
  z.preprocess(
    (a) => parseInt(z.string().parse(a), 10),
    z
      .number({
        required_error: __('error.required'),
        invalid_type_error: __('error.required'),
      })
      .gte(min, { message: __('error.minText', { min }) })
      .lte(max, { message: __('error.maxText', { max }) }),
  );

export const patientValidation = ({ min = 1, max = 60 } = {}) =>
  z
    .string({
      required_error: __('error.required'),
      invalid_type_error: __('error.required'),
    })
    .min(min, { message: __('error.minText', { min }) })
    .max(max, { message: __('error.maxText', { max }) });

export const minutesValidation = ({ min = 0, max = 59 } = {}) =>
  z.preprocess(
    (a) => parseInt(z.string().parse(a), 10),
    z
      .number({
        required_error: __('error.required'),
        invalid_type_error: __('error.required'),
      })
      .gte(min, { message: __('error.minText', { min }) })
      .lte(max, { message: __('error.maxText', { max }) }),
  );

export type TodoAddSchemaType = z.TypeOf<ReturnType<typeof todoAddSchema>>;
