import { Flex, Image, Box } from '@chakra-ui/react';
import { Text } from '../common';

interface Props {
  image: string;
  gameName: string;
  onClick: (e: any) => void;
  id: string;
}

export const BestToStartWIthGameCard = ({ image, gameName, onClick, id }: Props) => (
  <Flex
    direction="column"
    align="center"
    backgroundColor="#FFFFFF"
    borderRadius="10px"
    onClick={onClick}
    id={id}
    boxShadow="2px 8px 16px rgba(158, 137, 131, 0.25)"
  >
    <Image
      src={image}
      alt="alt.band"
      id={id}
      borderTopLeftRadius="10px"
      borderTopRightRadius="10px"
      h="100%"
    />
    <Flex h="80px" align="center" id={id}>
      <Text variant="BlueHeader">{gameName}</Text>
    </Flex>
  </Flex>
);
