import { Dispatch, SetStateAction } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { Step6 as PNG, Step6Content } from '../images/stepProgress/index';
import { MyModal } from '../common/MyModal';
import { DashboardStepProgressBar } from '../../components/common/DashboardStepProgressBar';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

export const Step6 = ({ setStep }: Props) => (
  <>
    <MyModal top="150px" left="600px">
      <Flex gap="20px" maxW="350px">
        <Flex direction="column" gap="30px" padding="20px 10px">
          <Flex gap="30px" align="center">
            <DashboardStepProgressBar text="6 z 6" image={PNG} />
          </Flex>
          <Text text="dashboardTutorial.step6" />
          <Flex gap="20px">
            <Button
              text="action.end"
              onClick={() => setStep((step: number) => step + 1)}
              variant="dasboardStep"
            />
          </Flex>
        </Flex>
      </Flex>
    </MyModal>
    <MyModal top="0px" left="950px" isOverlayed={false} isBackgroundTransparent>
      <Box>
        <Image src={Step6Content()} alt="alt.faq" />
      </Box>
    </MyModal>
  </>
);
