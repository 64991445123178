import { z } from 'zod';
import { passwordValidation, repeatPasswordValidation } from '../helpers/zod';

export const setPasswordSchema = () =>
  z
    .object({
      password: passwordValidation(),
      repeatPassword: passwordValidation(),
    })
    .refine(...repeatPasswordValidation());

export type SetPasswordType = z.TypeOf<ReturnType<typeof setPasswordSchema>>;
