import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';
import { components } from './components';
import { styles } from './styles';
import { defaultProps } from '../defaultProps';

export const theme = extendTheme({
  colors,
  components,
  styles,
  layerStyles: {
    modalHeader: {
      mb: 8,
    },
    centerContent: {
      position: 'absolute',
      maxH: '100vh',
      w: '100%',
      overflow: 'auto',
      p: [6, 8, null, 16],
      top: [null, '50%'],
      transform: [null, 'translateY(-50%)'],
    },
    menu: {
      p: 8,
      borderRadius: defaultProps.borderRadius,
    },
    menuWithHeader: {
      p: 5,
      borderBottomRadius: defaultProps.borderRadius,
    },
    menuContainer: {
      boxShadow: [
        '2px 8px 16px rgba(158, 137, 131, 0.25)',
        '6px 15px 40px rgba(158, 137, 131, 0.25)',
      ],
    },
    menuHeader: {
      borderTopRadius: defaultProps.borderRadius,
      w: '100%',
      h: '75px',
      objectFit: 'cover',
    },
    faq: {
      padding: '30px 60px',
      bg: colors.mainBg,
      w: '100vw',
    },
    faqTopics: {
      boxShadow: ['6px 15px 40px 2px rgba(158, 137, 131, 0.18)'],
      borderRadius: '10px',
    },
  },
  fonts: {
    body: 'Work Sans, sans-serif',
    heading: 'Work Sans, sans-serif',
  },
  shadows: { outline: 'none' },
});
