import { Link as ChakraLink } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { __ } from '../../../helpers/i18n';

interface Props {
  text: string;
  to?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const Link = ({ children, text, to, onClick }: Props) => {
  const navigate = useNavigate();

  return (
    <ChakraLink
      fontSize={[12, 16, null, 22]}
      onClick={() => {
        onClick?.();
        if (to) navigate(to);
      }}
    >
      {children}
      {text && __(text)}
    </ChakraLink>
  );
};
