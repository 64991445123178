import { Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ProfileIcon, FAQIcon, Text, Button } from '..';
import { RootState } from '../../reducers';
import { urls } from '../../config/url';
import { logout } from '../../reducers/user';
import { useFaq } from '../../hooks/useFaq';

interface Props {
  isWhite?: boolean;
}

export const Profile = ({ isWhite }: Props) => {
  const { isLoading, current: patient } = useSelector((state: RootState) => state.patients);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { onOpenFaq } = useFaq();

  return (
    <Flex gap="16px" align="center" position="relative">
      <ProfileIcon w="7" h="7" />

      <Menu>
        <MenuButton as={Text} variant={isWhite ? 'baseStyle' : 'whiteHeader'}>
          {patient?.name}
        </MenuButton>
        <MenuList>
          <MenuItem style={{}}>
            <Link to={urls.dashboard.choosePatient} style={{ color: '#1A1F5A' }}>
              Zmień profil
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={urls.dashboard.traning.addTraning} style={{ color: '#1A1F5A' }}>
              Kalendarz
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={urls.dashboard.patient.menu} style={{ color: '#1A1F5A' }}>
              Panel Główny
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={urls.dashboard.progress.trainingProgress} style={{ color: '#1A1F5A' }}>
              Zobacz wszystkie treningi
            </Link>
          </MenuItem>
          <MenuItem>
            <Button text="action.logout" onClick={() => dispatch(logout())} />
          </MenuItem>
        </MenuList>
      </Menu>

      <FAQIcon w="7" h="7" onClick={onOpenFaq} />
    </Flex>
  );
};
