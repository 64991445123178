import { defaultProps } from '../../defaultProps';

export const Button = {
  sizes: {
    md: {
      fontSize: [16, null, null, 22],
      p: [4, null, null, 6],
    },
  },
  baseStyle: {
    borderRadius: defaultProps.borderRadius,
    _hover: {
      opacity: 0.75,
    },
  },
  variants: {
    defaultVariant: {
      background: 'main',
      color: 'mainBg',
      fontWeight: 600,
      boxShadow: [
        '2px 8px 16px rgba(158, 137, 131, 0.25)',
        '6px 15px 40px rgba(158, 137, 131, 0.25)',
      ],
    },
    light: {
      background: 'light',
      fontWeight: 500,
      boxShadow: [
        '2px 8px 16px rgba(158, 137, 131, 0.25)',
        '6px 15px 40px rgba(158, 137, 131, 0.25)',
      ],
    },
    lightLeft: {
      w: '100%',
      justifyContent: 'flex-start',
      background: 'light',
      fontWeight: 300,
      boxShadow: [
        '2px 8px 16px rgba(158, 137, 131, 0.25)',
        '6px 15px 40px rgba(158, 137, 131, 0.25)',
      ],
    },
    lightIcon: {
      background: 'light',
      fontSize: 24,
      boxShadow: [
        '2px 8px 16px rgba(158, 137, 131, 0.25)',
        '6px 15px 40px rgba(158, 137, 131, 0.25)',
      ],
    },
    transparentIcon: {
      background: 'rgba(0, 0, 0, 0)',
      fontSize: 24,
      boxShadow: 0,
    },
    transparent: {
      background: 'rgba(0, 0, 0, 0)',
      color: 'main',
      p: 0,
      boxShadow: 0,
      fontWeight: 300,
    },
    inactiveOutline: {
      background: 'rgba(0, 0, 0, 0)',
      color: 'main',
      border: '2px solid #D8D8E1',
      justifyContent: 'flex-start',
      fontWeight: 300,
    },
    connect: {
      background: 'white',
      color: 'main',
      fontWeight: '500',
    },
    connectBold: {
      background: 'white',
      color: 'main',
      fontWeight: '600',
      boxShadow: [
        '2px 8px 16px rgba(158, 137, 131, 0.25)',
        '6px 15px 40px rgba(158, 137, 131, 0.25)',
      ],
    },
    outline: {
      background: 'transparent',
      color: 'white',
      border: '1px solid white',
    },
    dasboardStep: {
      background: '#56E097',
      color: 'light',
      boxShadow: 0,
      fontWeight: 500,
    },
    delete: {
      background: '#FF000F',
      color: 'white',
    },
  },
};
