import { lazy, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Status } from 'types';
import { RootState } from '../../../reducers';
import { persistentNotification } from '../../../helpers/notifications';
import { deepEqual } from '../../../helpers/deepEqual';

const Auth = lazy(() => import('../../../views/Auth'));
const Dashboard = lazy(() => import('../../../views/Dashboard'));

export const AppRouting = () => {
  const isOnline = useSelector((state: RootState) => state.appStatus.isOnline, deepEqual);
  const user = useSelector((state: RootState) => state.user.details, deepEqual);

  useEffect(() => {
    if (!isOnline) persistentNotification('application.isOffline');
  }, []);

  const getIndexUrl = useCallback(
    () => (user && user.status !== Status.Setup ? '/dashboard/' : '/auth'),
    [user],
  );

  return (
    <Routes>
      {user && user.status !== Status.Setup ? (
        <Route path="dashboard/*" element={<Dashboard />} />
      ) : (
        <Route path="auth/*" element={<Auth />} />
      )}
      <Route path="*" element={<Navigate to={getIndexUrl()} replace />} />
    </Routes>
  );
};
