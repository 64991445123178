import { ReactNode } from 'react';
import { Container, Image, Box, IconButton } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sidebarPhoto } from '../..';
import { __ } from '../../../helpers/i18n';
import { RootState } from '../../../reducers';
import { urls } from '../../../config/url';

interface Props {
  children: ReactNode;
}

export const AuthLayout = ({ children }: Props) => {
  const patient = useSelector((state: RootState) => state.patients.current);
  return (
    <Container variant="mainAuth" as="main">
      <Image w="40%" h="100vh" src={sidebarPhoto()} alt={__('alt.sidebarPhoto')} />
      <Container variant="centerContentWrapper" centerContent>
        {patient && (
          <IconButton
            position="absolute"
            aria-label={__('action.close')}
            top="1rem"
            right="1rem"
            variant="lightIcon"
            size="lg"
            icon={<MdClose />}
            as={Link}
            to={urls.dashboard.patient.menu}
          />
        )}
        <Box layerStyle="centerContent">{children}</Box>
      </Container>
    </Container>
  );
};
