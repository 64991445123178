import {
  Questionnaire,
  QuestionnaireFeel,
  QuestionnaireSleep,
  QuestionnaireAfter,
  GameResultType,
} from 'types';

export interface TraningEntity {
  id: string;
  isFinished: boolean;
  traningDate: Date;
  questionnaireFeel: QuestionnaireFeel;
  questionnaireSleep: QuestionnaireSleep;
  questionnaireAfter: QuestionnaireAfter;
}

export type TrainingType = {
  id: string;
  isFinished: boolean;
  traningDate: Date;
  questionnaireFeel: QuestionnaireFeel;
  questionnaireSleep: QuestionnaireSleep;
  questionnaireAfter: QuestionnaireAfter;
  gameResult: GameResultType[];
};

export interface PatientsTrainingsRes {
  trainings: TrainingType[];
}

export interface TraningTypeRes {
  id: string;
  traningDate: Date;
  isFinished: boolean;
  patientId: string;
  questionnaireFeel: QuestionnaireFeel;
  questionnaireSleep: QuestionnaireSleep;
  questionnaireAfter: QuestionnaireAfter;
}

export interface TraningType {
  id: string;
  traningDate: Date;
  questionnaireFeel: QuestionnaireFeel;
  questionnaireSleep: QuestionnaireSleep;
  questionnaireAfter: QuestionnaireAfter;
}

export interface TraningRes {
  id: string;
}

export interface TraningReq {
  traningId?: string;
  questionnaireAfter: QuestionnaireAfter;
  questionnaireFeel: QuestionnaireFeel;
  questionnaireSleep: QuestionnaireSleep;
}

export enum TrainingPer {
  Week = 'Week',
  Month = 'Month',
  Range = 'Range',
}

export interface Last7TrainingsRes {
  trainings: TrainingType[];
}
