import { CSSProperties, FC, ReactNode } from 'react';
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  CSSObject,
} from '@chakra-ui/react';
import { __ } from '../../../helpers/i18n';

interface AccordionComponentProps {
  text?: string;
  textWithoutTranslation?: string;
  textAlign?: 'center' | 'left' | 'right';
  _last?: CSSObject;
  _first?: CSSObject;
  style?: CSSProperties;
  children?: ReactNode;
}

export const AccordionComponent: FC<AccordionComponentProps> = ({
  text = '',
  textWithoutTranslation,
  textAlign = 'left',
  _first = {},
  _last = {},
  style = {},
  children,
}) => (
  <AccordionItem _last={_last} _first={_first} style={style}>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign={textAlign}>
          {textWithoutTranslation ?? __(text)}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>{children}</AccordionPanel>
  </AccordionItem>
);
