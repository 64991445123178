export const Input = {
  sizes: {
    md: {
      field: {
        fontSize: [16, null, null, 22],
        py: [2, null, null, 7],
      },
    },
  },
  baseStyle: {
    field: {
      borderWidth: 2,
      borderColor: 'border',
      background: 'mainBg',
    },
  },
};
