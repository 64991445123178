import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useFaq } from '../../hooks/useFaq';
import { FaqContent } from './FaqContent';

export const Faq = () => {
  const { isOpenFaq, onCloseFaq, getFaqData } = useFaq();

  useEffect(() => {
    if (isOpenFaq) {
      getFaqData();
    }
  }, [isOpenFaq]);

  if (!isOpenFaq) return null;

  return ReactDOM.createPortal(
    <FaqContent onClose={onCloseFaq} />,
    document.getElementById('portal') as HTMLElement,
  );
};
