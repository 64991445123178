type Debounce<T> = (...args: T[]) => void;

export const debounce = <F extends Debounce<Parameters<F>> | ((...args: Parameters<F>) => void)>(
  func: F,
  waitFor: number,
): ((...args: Parameters<F>) => void) => {
  let timeout: ReturnType<typeof setTimeout>;

  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
};
