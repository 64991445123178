import { MouseEvent as ReactMouseEvent, ReactElement, ReactNode } from 'react';
import { Button } from '../config/theme/components/button';
import { FitFactorState, FitLevel } from '../hooks/bluetooth/useFitFactor';

export type FormFieldContextType = {
  name: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  id?: string;
};

export type FitValuesContextType = {
  scheduleComputationFitFactor: () => void;
  scheduleComputationFitLevel: () => void;
  fitFactorState: FitFactorState[];
  fitLevel: FitLevel;
  isReady: boolean;
};

export type ProviderType<T> = {
  children: ReactNode;
  value?: T;
};

export interface ButtonProps {
  text?: string;
  to?: string;
  type?: 'button' | 'reset' | 'submit';
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: ReactNode;
  isDisabled?: boolean;
  variant?: keyof typeof Button.variants;
  prefixIcon?: string;
  suffixIcon?: string;
  ml?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
}

export interface DefaultFormProps {
  isDisabled: boolean;
}

export enum ResetState {
  Input,
  Ready,
}

export enum HeadbandStartStep {
  Initialization,
  DiodeActivated,
  ButtonActivated,
}
