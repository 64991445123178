import { SectionType } from '../types/bluetoothMessage';

export const decodeUTF8Message = (message: DataView) => {
  const decoder = new TextDecoder('utf-8');
  return decoder.decode(message);
};

export const encodeUTF8Message = (message: string) => {
  const encoder = new TextEncoder();
  const encodedMessage = encoder.encode(message).buffer;
  return new DataView(encodedMessage);
};

export const isSectionType = <T extends SectionType>(
  section: SectionType,
  sectionType: T['idS'],
): section is T => section.getSectionEnum() === sectionType;
