import { Dispatch, SetStateAction } from 'react';
import { Flex, Image, Box } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Text } from '../common/Text';
import { Button } from '../common/Button';
import { Step2 as PNG, Step2Content } from '../images/stepProgress/index';
import { MyModal } from '../common/MyModal';
import { DashboardStepProgressBar } from '../../components/common/DashboardStepProgressBar';

interface Props {
  setStep: Dispatch<SetStateAction<number>>;
}

export const Step2 = ({ setStep }: Props) => (
  <>
    <MyModal top="300px" left="120px">
      <Flex gap="20px" maxW="350px">
        <Flex direction="column" gap="30px" padding="20px 10px">
          <Flex gap="30px" align="center">
            <DashboardStepProgressBar text="2 z 6" image={PNG} />
          </Flex>
          <Text text="dashboardTutorial.step2" />
          <Flex gap="20px">
            <Button
              text="action.next"
              onClick={() => setStep((step: number) => step + 1)}
              variant="dasboardStep"
              rightIcon={<ChevronRightIcon w="7" h="7" />}
            />
          </Flex>
        </Flex>
      </Flex>
    </MyModal>
    <MyModal top="180px" left="585px" isOverlayed={false} isBackgroundTransparent>
      <Box>
        <Image src={Step2Content()} alt="alt.tip" w="485px" />
      </Box>
    </MyModal>
  </>
);
