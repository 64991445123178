export const Heading = {
  sizes: {
    xl: {
      fontSize: [28, 36, null, 48],
    },
  },
  baseStyle: {
    fontWeight: 500,
  },
};
