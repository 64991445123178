import { ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';
import { DashboardHeader } from '../../DashboardHeader';
import { Faq } from '../../Faq';

interface Props {
  children: ReactNode;
  gap?: string | number;
  isWhite: boolean;
}

export const MainLayout = ({ children, isWhite, gap = '172px' }: Props) => (
  <Flex
    direction="column"
    gap={gap}
    maxH="100vh"
    h="100vh"
    backgroundColor={isWhite ? '#F9F9F9' : '#181d55'}
    color={isWhite ? '#181d55' : '##FFFFFF'}
  >
    <DashboardHeader isWhite={isWhite} />
    <Faq />
    {children}
  </Flex>
);
