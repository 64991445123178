import { z } from 'zod';
import { passwordValidation, textIsRequired, textValidation } from '../helpers/zod';

export const accountSetupLoginSchema = () =>
  z.object({
    login: textValidation({ min: 8 }).and(textIsRequired()),
    password: passwordValidation(),
  });

export type AccountSetupLoginType = z.TypeOf<ReturnType<typeof accountSetupLoginSchema>>;
