import { Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { BatteryStatus } from '../BatteryStatus';
import { Profile } from '../Profile';

interface Props {
  isWhite?: boolean;
}

export const DashboardHeader = ({ isWhite }: Props) => {
  const dispatch = useDispatch();

  return (
    <Flex
      backgroundColor={isWhite ? 'light' : 'main'}
      color={isWhite ? 'main' : 'light'}
      align="center"
      justify="space-around"
      gap="350px"
      fontWeight="bold"
      padding="24px"
    >
      <BatteryStatus isWhite={isWhite} />
      <Profile isWhite={isWhite} />
    </Flex>
  );
};
