export const getIntfromBigEndianByteArray = (bytes: Uint8Array) =>
  bytes.length !== 4
    ? null
    : (bytes[0] << 24) | ((bytes[1] & 0xff) << 16) | ((bytes[2] & 0xff) << 8) | (bytes[3] & 0xff);

export const getIntFromLittleEndianByteArray = (bytes: Uint8Array) =>
  bytes.length !== 4
    ? null
    : (bytes[3] << 24) | ((bytes[2] & 0xff) << 16) | ((bytes[1] & 0xff) << 8) | (bytes[0] & 0xff);

export const asUnsignedByte = (value: number) => value & 0xff;

export const getInt24Value = (low: number, mid: number, hi: number) => {
  let result = (low & 0xff) | ((mid & 0xff) << 8) | ((hi & 0xff) << 16);
  if (hi < 0) result |= 0xff_00_00_00;

  return result;
};

export const getUnsignedShortValue = (low: number, hi: number) => (low & 0xff) | ((hi & 0xff) << 8);

export const getShortValue = (low: number, hi: number) => (low & 0xff) | ((hi & 0xff) << 8);

export const getLongFromLittleEndianByteArray = (source: Uint8Array) => {
  const { length } = source;
  let result = 0;
  for (let i = 0; i < length; i += 1) {
    result += (source[i] & 0xff) << (8 * i);
  }

  return result;
};

export const macAddressFromByteArray = (byteMacAddress: Uint8Array) => {
  const MAC_LENGTH = 6;
  if (byteMacAddress.length !== MAC_LENGTH) return null;
  const result: string[] = [];

  byteMacAddress.forEach((byteChunk) => {
    const stringRes = (byteChunk & 0xff).toString(16);
    result.push((stringRes.length < 2 ? `0${stringRes}` : stringRes).toUpperCase());
  });

  return result.join(':');
};

export const getBitsValue = (source: number, offset: number, length: number) => {
  if (offset + length > 8) throw new RangeError('offest and length are higher than 8');
  return (source & (((0x01 << length) - 1) << offset)) >> offset;
};

export const shortToLittleEndianByteArray = (source: number) => {
  const result: Uint8Array = new Uint8Array(2);

  result[0] = source & 0xff;
  result[1] = (source & 0xff_00) >> 8;

  return result;
};

export const copyArrayValues = <T>(
  src: Uint8Array | T[],
  srcPos: number,
  dst: Uint8Array | T[],
  dstPos: number,
  length: number,
) =>
  src.slice(srcPos, srcPos + length).forEach((e, i) => {
    dst[dstPos + i] = e;
  });

export const numberArrayCopy = (
  src: number[],
  srcPos: number,
  dst: number[],
  dstPos: number,
  length: number,
) =>
  src.slice(srcPos, srcPos + length).forEach((e, i) => {
    dst[dstPos + i] = e;
  });

export const setBitsInByte = (
  source: number,
  bitsValue: number,
  bitsOffset: number,
  bitsLength: number,
) => {
  if (bitsOffset + bitsLength > 8) throw new RangeError('bit offset is out of range');
  return (
    (source & (0xff_ff & ~(((0x01 << bitsLength) - 1) << bitsOffset))) |
    ((bitsValue & 0xff) << bitsOffset)
  );
};

export const hexadecimalStringFrom = (array: Uint8Array) => {
  let sb = '';
  array.forEach((byte) => {
    const stringRes = (byte & 0xff).toString(16);
    sb += (stringRes.length < 2 ? `0${stringRes} ` : `${stringRes} `).toUpperCase();
  });
  return sb;
};

export const longToLittleEndianByteArray = (source: number, length: number) => {
  const result: Uint8Array = new Uint8Array(length);
  const maxOffset = length - 1;
  for (let i = 0; i <= maxOffset; i += 1) {
    result[i] = (source >> (8 * i)) & 0xff;
  }

  return result;
};

export const macAddressToByteArray = (macAddress: string) => {
  const chunks: string[] = macAddress.split(':');
  const arrayKeys = [0, 1, 2, 3, 4, 5];
  const getDecimalFromHexadecimalString = (index: number) => 0xff & parseInt(chunks[index], 16);

  if (chunks.length !== 6) throw new Error('Unnaceptable macAddress provided');

  return new Uint8Array(arrayKeys.map(getDecimalFromHexadecimalString));
};

export const findPattern = (
  source: Uint8Array,
  pattern: Uint8Array,
  offset: number,
  limit: number,
) => {
  if (limit > source.length) return null;

  let patternLocation = -1;
  // Scan for pattern. Protect from buffer overflow.
  for (let sourcePosition = offset; sourcePosition < limit - pattern.length; sourcePosition += 1) {
    // Find 1st symbol from pattern
    if (source[sourcePosition] === pattern[0]) {
      // Copy pattern candidate
      const candidatePattern: Uint8Array = source.slice(
        sourcePosition,
        sourcePosition + pattern.length,
      );
      if (JSON.stringify(candidatePattern) === JSON.stringify(pattern)) {
        patternLocation = sourcePosition;
        break;
      }
    }
  }

  return patternLocation;
};

/**
 * Convert date time to 8 bytes BCD byte array.
 * Byte order:
 * +--------------+-----+-----+------+---------+-----+-------+----+----+
 * |  byte offset |  0  |  1  |   2  |    3    |  4  |   5   | 6  | 7  |
 * +--------------+-----+-----+------+---------+-----+-------+----+----+
 * |  meaning/val | Sec | Min | Hour | Weekday | Day | Month |   Year  |
 * +--------------+-----+-----+------+---------+-----+-------+---------+
 * Example:
 * Date: 2020-02-29 19:06:00 will be represented as array:
 * [0x00, 0x06, 0x19, 0x06, 0x29, 0x02, 0x20, 0x20]
 */
export const dateTimeToBcdByteArray = (dateTime: Date) => dateToBcdArray(dateTime);

/**
 * Convert date time in long representation to 8 bytes BCD byte array.
 * Byte order:
 * +--------------+-----+-----+------+---------+-----+-------+----+----+
 * |  byte offset |  0  |  1  |   2  |    3    |  4  |   5   | 6  | 7  |
 * +--------------+-----+-----+------+---------+-----+-------+----+----+
 * |  meaning/val | Sec | Min | Hour | Weekday | Day | Month |   Year  |
 * +--------------+-----+-----+------+---------+-----+-------+---------+
 * Example:
 * Date: 2020-02-29 19:06:00 will be represented as array:
 * [0x00, 0x06, 0x19, 0x06, 0x29, 0x02, 0x20, 0x20]
 * Date: 2021-03-15 16:17:37 will be represented as array:
 * [0x37, 0x17, 0x16, 0x01, 0x15, 0x03, 0x21, 0x20]
 */
export const longDateTimeToBcdByteArray = (dateTime: number) => dateToBcdArray(new Date(dateTime));

export const bcdArrayToLongDate = (bcdDataTime: Uint8Array) => +bcdArrayToDate(bcdDataTime);

/**
 * Convert 8 bytes BCD byte array to dateTime representation.
 * Byte order:
 * +--------------+-----+-----+------+---------+-----+-------+----+----+
 * |  byte offset |  0  |  1  |   2  |    3    |  4  |   5   | 6  | 7  |
 * +--------------+-----+-----+------+---------+-----+-------+----+----+
 * |  meaning/val | Sec | Min | Hour | Weekday | Day | Month |   Year  |
 * +--------------+-----+-----+------+---------+-----+-------+---------+
 * Example:
 * Array:  [0x00, 0x06, 0x19, 0x06, 0x29, 0x02, 0x20, 0x20]
 * will give as an output date: 2020-02-29 19:06:00
 */
export const bcdArrayToDateTime = (bcdDataTime: Uint8Array) =>
  bcdArrayToDate(bcdDataTime)?.getTime();

export const bcdArrayToDate = (bcdDataTime: Uint8Array) => {
  if (bcdDataTime.length !== 8) return new Date();
  const year = twoDigitsFromBcdByte(bcdDataTime[7]) * 100 + twoDigitsFromBcdByte(bcdDataTime[6]);
  const month = twoDigitsFromBcdByte(bcdDataTime[5]) - 1;
  const day = twoDigitsFromBcdByte(bcdDataTime[4]);
  const hour = twoDigitsFromBcdByte(bcdDataTime[2]);
  const minute = twoDigitsFromBcdByte(bcdDataTime[1]);
  const sec = twoDigitsFromBcdByte(bcdDataTime[0]);

  return new Date(year, month, day, hour, minute, sec, 0);
};

export const dateToBcdArray = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const sec = date.getSeconds();
  // in Date implementation days of week always starts from sunday - so corrected below
  // Additionally days go from 1 to 7 [so saturday can't be 0, it should be 7]
  const weekDay = date.getDay() === 0 ? 7 : date.getDay();

  const yearBytes = [twoDigitsToBCDByte(year % 100), twoDigitsToBCDByte(year / 100)];
  const monthBytes = [twoDigitsToBCDByte(month)];
  const dayBytes = [twoDigitsToBCDByte(day)];
  const weekDayBytes = [twoDigitsToBCDByte(weekDay)];
  const hourBytes = [twoDigitsToBCDByte(hour)];
  const minuteBytes = [twoDigitsToBCDByte(minute)];
  const secBytes = [twoDigitsToBCDByte(sec)];

  return new Uint8Array([
    ...secBytes,
    ...minuteBytes,
    ...hourBytes,
    ...weekDayBytes,
    ...dayBytes,
    ...monthBytes,
    ...yearBytes,
  ]);
};

export const twoDigitsFromBcdByte = (bcdByte: number) => (bcdByte >> 4) * 10 + (bcdByte & 0x0f);

export const twoDigitsToBCDByte = (twoDigits: number) => {
  const upper = ((twoDigits / 10) << 4) & 0xf0;
  const lower = twoDigits % 10 & 0x0f;

  return upper | lower;
};

// We can't spread Int8Array, but we need array with 0 to 255 values
const getFakeInt8ArrayFromArray = (array: ArrayBufferLike | [number]) =>
  new Array(new Int8Array(array).reverse());

export const DoubleToBytes = (double: number) =>
  getFakeInt8ArrayFromArray(new Float64Array([double]).buffer);

export const LongToBytes = (long: number) =>
  getFakeInt8ArrayFromArray(new BigInt64Array([BigInt(long)]).buffer);

export const IntToBytes = (int: number) => getFakeInt8ArrayFromArray(new Int32Array([int]).buffer);

export const BooleanToBytes = (bool: boolean) => getFakeInt8ArrayFromArray([+bool]);
