import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from './url';
import { store } from './store';
import { clearUserData } from '../reducers/user';

const REQ_TIMEOUT = 20000;

export const axiosRequestConfigurationJson: AxiosRequestConfig = {
  baseURL: apiUrl,
  withCredentials: true,
  timeout: REQ_TIMEOUT,
  headers: {
    'content-type': 'application/json',
  },
};

const axiosInstance = axios.create(axiosRequestConfigurationJson);

axiosInstance.interceptors.request.use(
  (c) => c,
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(clearUserData());
    }
    return error;
  },
);

export { axiosInstance };
