import { Center, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { Button, Text } from '../../../components/common';

interface FileItemProps {
  text: string;
  onClick: () => void;
}

export const FileItem: FC<FileItemProps> = ({ text, onClick }) => (
  <Flex padding="2.5">
    <Center flex="1">
      <Text>{text}</Text>
    </Center>
    <Center>
      <Button text="action.download" onClick={onClick} />
    </Center>
  </Flex>
);
