import { Heading as ChakraHeading } from '@chakra-ui/react';
import { __ } from '../../../helpers/i18n';

interface Props {
  text: string;
  layerStyle?: string;
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
}

export const Heading = ({ text, as, layerStyle, size }: Props) => (
  <ChakraHeading fontWeight="600" as={as} size={size} layerStyle={layerStyle}>
    {__(text)}
  </ChakraHeading>
);
