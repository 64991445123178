import { Grid, GridItem } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { GridContainer } from '../../common';

interface FaqTopicsProps {
  children: ReactNode;
}

export const FaqTopics: FC<FaqTopicsProps> = ({ children }) => (
  <GridItem layerStyle="faqTopics" padding="10" rowSpan={2}>
    <GridContainer gap="5">{children}</GridContainer>
  </GridItem>
);
