import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { RegularGameCard } from './RegularGameCard';
import { BestToStartWIthGameCard } from './BestToStartWIthGameCard';

interface Props {
  handleClick: (e: any) => void;
}
export const RegularGameCards = ({ handleClick }: Props) => {
  const regular = useSelector((state: RootState) => state.game.rest);

  return (
    <>
      {regular.map((x) => (
        <BestToStartWIthGameCard
          gameName={x.gameName}
          image={x.image}
          onClick={(e) => handleClick(e)}
          key={x.gameName + Math.random()}
          id={x.gameType}
        />
      ))}
    </>
  );
};
