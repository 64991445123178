import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Text } from '../../../components/common/Text';
import { RootState } from '../../../reducers';
import { BatteryStatus } from '..';
import { __ } from '../../../helpers/i18n';

interface Props {
  backgroundColor: string;
}

const BatteryStatusWrapper = styled.div<Props>`
  border: 2px solid ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BatteryStatusBox = () => {
  const batteryStatus = useSelector((state: RootState) => state.headband.sts.battery);
  return (
    <BatteryStatusWrapper backgroundColor={batteryStatus <= 30 ? '#FF000F' : '#72ebab'}>
      <BatteryStatus isWhite />
      <Text text="putting.BatteryLevel" variant="BlueHeader" fontSize="1.5rem" />
    </BatteryStatusWrapper>
  );
};
