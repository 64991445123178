import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleError } from '../helpers/handleError';
import { axiosInstance } from '../config/axios';

import { ApiPayload } from '../hooks/useApi';
import { apiUrls } from '../config/url';

interface QuestionnaireInitialState {
  feel: string;
  sleep: string;
  after: string;
}

const initialState: QuestionnaireInitialState = {
  feel: '',
  sleep: '',
  after: '',
};

export const sendQuestionnaireBeforeTraning = async (feel: string, sleep: string) => {
  const res = await axiosInstance.post(apiUrls.questionnaire.beforeTraning, {
    feel,
    sleep,
    date: new Date(),
  });
};

const questionnaire = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    setFeel: (state, action) => {
      state.feel = action.payload;
    },
    setSleep: (state, action) => {
      state.sleep = action.payload;
    },
    setAfter: (state, action) => {
      state.after = action.payload;
    },
  },
});

export const { setFeel, setSleep, setAfter } = questionnaire.actions;

export default questionnaire.reducer;
