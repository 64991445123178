import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch } from 'react-redux';
import { ApiOkResult, AuthAccountSetupRequest } from 'types';
import { useApi } from '../../../hooks/useApi';
import { successNotification } from '../../../helpers/notifications';
import { accountSetup, AccountSetupType } from '../../../formSchemas/accountSetup';
import { logout, setIsSetup } from '../../../reducers/user';
import { AccountSetupForm, FormWrapper, Heading } from '../../../components';
import { apiUrls } from '../../../config/url';

export const AccountSetup = () => {
  const methods = useForm<AccountSetupType>({
    resolver: zodResolver(accountSetup()),
  });
  const { post, inProgress } = useApi();
  const dispatch = useDispatch();

  const submitHandler = async (formData: AccountSetupType) => {
    const { repeatPassword, ...rest } = formData;

    const res = await post<ApiOkResult, AuthAccountSetupRequest>(apiUrls.auth.accountSetup, rest);
    if (res) {
      successNotification('notification.accountSetupConfirmed');
      await dispatch(setIsSetup(false));
      await dispatch(logout());
    }
  };

  return (
    <FormWrapper<AccountSetupType> methods={methods} submitHandler={submitHandler}>
      <Heading as="h1" text="header.setupAccount" />
      <AccountSetupForm isDisabled={inProgress} />
    </FormWrapper>
  );
};
