const labelTransform = [
  'scale(0.85) translateY(-24px)',
  null,
  null,
  'scale(0.73) translateY(-40px)',
];

export const Form = {
  baseStyle: {
    container: {
      'div[role=status]': {
        h: [5, 8],
        mt: 0.5,
        'div[role=note], div[role=alert]': {
          fontSize: [12, 14],
          m: 0,
        },
      },
    },
  },
  variants: {
    floating: {
      container: {
        _focusWithin: {
          label: {
            transform: labelTransform,
          },
        },
        'div[placeholder] + label, .chakra-select__wrapper + label': {
          transform: labelTransform,
        },
        'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
          transform: labelTransform,
        },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: 'absolute',
          backgroundColor: 'mainBg',
          pointerEvents: 'none',
          fontSize: [12, 16, null, 22],
          mx: 3,
          px: 1,
          my: [3, 2, null, 4],
          transformOrigin: 'left top',
        },
      },
    },
  },
};
