import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../../../views/Auth/Login';
import { AdminLogin } from '../../../views/Auth/AdminLogin';
import { AccountSetupLogin } from '../../../views/Auth/AccountSetupLogin';
import { ResetPassword } from '../../../views/Auth/ResetPassword';
import { SetPassword } from '../../../views/Auth/SetPassword';
import { Activate } from '../../../views/Auth/Activate';

interface Props {
  isSetup: boolean;
}

export const RouteInactiveUser = ({ isSetup }: Props) => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="login/admin" element={<AdminLogin />} />
    <Route path="account-setup-login" element={<AccountSetupLogin />} />
    <Route path="reset-password" element={<ResetPassword />} />
    <Route path="set-password/:userId/:tokenId" element={<SetPassword />} />
    <Route path="activate/:userId/:tokenId" element={<Activate />} />
    <Route path="*" element={<Navigate to={isSetup ? 'account-setup-login' : 'login'} replace />} />
  </Routes>
);
