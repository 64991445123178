import { combineReducers } from '@reduxjs/toolkit';
import user from './user';
import appStatus from './appStatus';
import headband from './headband';
import modal from './modal';
import patients from './patients';
import game from './game';
import headbandMeasurements from './headbandMeasurements';
import questionnaire from './questionnaire';
import training from './training';
import todos from './todos';
import choosedDate from './choosedDate';
import files from './files';
import admin from './admin';
import faq from './faq';

const rootReducer = combineReducers({
  user,
  appStatus,
  headband,
  headbandMeasurements,
  modal,
  patients,
  game,
  questionnaire,
  training,
  todos,
  choosedDate,
  files,
  admin,
  faq,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
