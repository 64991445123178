import { ReactNode } from 'react';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { __ } from '../../../helpers/i18n';

interface Props {
  to?: string;
  isDisabled?: boolean;
  text: string;
  children: ReactNode;
}

interface StyledProps {
  isDisabled: boolean | undefined;
}

const Button = styled.button<StyledProps>`
  border-radius: 10px;
  font-weight: 600;
  background-color: #1a1f5a;
  color: white;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : '1')};
  min-width: 150px;
  font-size: 24px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MyButton = ({ to, isDisabled, text, children }: Props) => {
  const navigate = useNavigate();

  const navigateIfNeed = () => {
    if (to) navigate(to);
  };

  return (
    <Button disabled={!!isDisabled} isDisabled={isDisabled} onClick={navigateIfNeed}>
      {text && __(text)}
      {children}
    </Button>
  );
};
