import Polyglot from 'node-polyglot';
import { AppConfig } from '../config/app';

interface Phrases {
  [key: string]: string | Phrases;
}

export interface TestI18nData {
  locale: string;
  langNames: string[];
  phrases: Phrases;
}

export class I18n {
  loaded: boolean;

  langNames: string[];

  currentLang: string | null;

  polyglot?: Polyglot;

  constructor() {
    this.loaded = false;
    this.langNames = [];
    this.currentLang = null;
  }

  private _isLoaded() {
    return this.loaded;
  }

  async setData(data: TestI18nData) {
    this.polyglot = new Polyglot({
      phrases: data.phrases,
      locale: data.locale,
    });
    const htmlElement = document.querySelector('html') as HTMLElement;
    htmlElement.lang = data.locale;

    this.currentLang = data.locale;
    this.langNames = data.langNames;
    this.loaded = true;
  }

  async load(lang: string, clb?: () => void, dataForTesting?: TestI18nData): Promise<void> {
    if (dataForTesting) await this.setData(dataForTesting);
    else {
      const fileName = lang in AppConfig.TRANSLATIONS ? lang : AppConfig.TRANSLATIONS[0];
      const response = await fetch(`/assets/i18n/${fileName}.json`, { cache: 'no-cache' });
      const i18nInfoData = await response.json();

      await this.setData(i18nInfoData);
    }

    if (typeof clb === 'function') {
      clb();
    }
  }

  __(key: string, options?: Polyglot.InterpolationOptions | number): string {
    if (!this._isLoaded()) return '';
    return this.polyglot ? this.polyglot.t(key, options) : '';
  }
}

export const i18n = new I18n();
export const __ = (text: string, args?: Polyglot.InterpolationOptions | number): string =>
  i18n.__(text, args);
